import React from "react"
import CardUi from "../Cards/CardUI"
import defaultImg from "../../../assets/img/team/profile-picture-1.jpg"
import history from '../../../history'
import axios from "../../../api";
import ContentLoader from 'react-content-loader'
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { connect } from "react-redux";
import { logout } from "../../../actions"
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
const rows = 2
const columns = 4
const coverHeight = 385
const coverWidth = 280
const padding = 20
const speed = 1

const coverHeightWithPadding = coverHeight + padding
const coverWidthWithPadding = coverWidth + padding
const initial = 0
const covers = Array(columns * rows).fill(2)

class ListPackages extends React.Component {
    state = { 
        packages: [], 
        isLoading: true ,
        searchedName: "" 
    }


    componentDidMount() {
        this.fetchPackages()

    }
    searchName = async ($event) =>{
        console.log($event.target.value)
        this.setState({searchedName: $event.target.value})
        try{  
        this.setState({isLoading:true})
          let res = []
          if($event.target.value){
            res=(await axios.get(`/v1/packages?name=`+$event.target.value, {
                    headers: {
                      Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                    }
                  })).data.data
          }
          else{
            res=(await axios.get(`/v1/packages`,{
                    headers: {
                      Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                    }
                  })).data.data        
          }
          console.log("result",res)
            const packages = res.result;
            this.setState({ packages })
            this.setState({isLoading:false})
        //   setHairStyleCount(res.count)
        //   setPageCount(Math.ceil(res.count / itemsPerPage));                                
          
        }catch(err){
            this.setState({isLoading:true})
          if(err.response&&(err.response.status==401||err.response.status==403)){
            this.props.logout(String(window.location.href));
        }
    
        console.log(err);
        }    
      }    
    fetchPackages = async () => {
        try {
            const res = await axios.get("/v1/packages", {
                headers: {
                    Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                }
            })
            const packages = res.data.data.result;
            // packages.map(async(package)=>{
            //     await axios.get(`/hairstyles/byPackage/${package._id}`)
            // })
            this.setState({ packages })
            this.setState({ isLoading: false })
        } catch (err) {
            console.log(err);
            this.setState({ isLoading: true })
            if (err.response && (err.response.status == 401 || err.response.status == 403)) {
                this.props.logout(String(window.location.href));
            }
        }
    }

    deletePackage = async (id) => {
        this.setState({isLoading:true})
        try {
            await axios.delete(`/v1/packages/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                }
            })
            let packagesAll = this.state.packages
            let itemIndex = packagesAll.findIndex((el)=>el._id === id)
            if(itemIndex !== -1){
                packagesAll.splice(itemIndex,1)
                this.setState({ packages: packagesAll})
                this.setState({isLoading:false})
            }else{
                this.setState({isLoading:false})
                alert("Item not found!")
            }
        } catch (err) {
            this.setState({isLoading:false})
            if (err.response && (err.response.status == 401 || err.response.status == 403)) {                
                this.props.logout(String(window.location.href));
            }
        }
    }

    onButtonClick = (pkg) => {
        console.log(pkg)
        history.push(`/components/listhairstyles/${pkg._id}/${pkg.title}`)
        console.log("button is clicked")
    }

    displayImage = (img) => `data:image/png;base64,${img}`

    renderPackages = () =>
        this.state.packages.map((pkg, i) => {
            return (
                
                <div style={{margin:"5px"}}>
                    {console.log(pkg,"PACKAGE")}
                    <CardUi imgSrc={pkg.picture ? this.displayImage(pkg.picture) : defaultImg}
                        btnText="See Hairstyles"
                        editButton="Edit Package"
                        deleteButton="Delete Package"
                        title={pkg.price}
                        numberOfHairstyles={pkg.hairstyles.length}
                        description={pkg.description}
                        deleteItem={() => this.deletePackage(pkg._id)}
                        editItem={() => history.push(`/components/editpackages/${pkg._id}`)}
                        onButtonClick={() => this.onButtonClick(pkg)}
                    ></CardUi>
                </div>
            )
        })



    render() {
        console.log("list packages called");
        
            return (                
                <div>
                {this.state.isLoading&&<span className="loading">Loading...</span>}                                                
                <div style={{ margin:'10px' }} className="d-flex align-items-center">
                <Form className="navbar-search">
                  <Form.Group id="topbarSearch">
                    <InputGroup className="input-group-merge search-bar">
                      <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                      <Form.Control onChange={this.searchName}  type="text" placeholder="Search" />
                    </InputGroup>
                  </Form.Group>
                </Form>
              </div>                
                <div className="container-fluid  justify-content-center">
                    <Button variant="contained" style={{ backgroundColor: "#262B40", color: "white" }} disableElevation startIcon={<Add />} size="large" onClick={() => history.push("/components/createpackages")}>
                        Create Package
                </Button>
                    <div style={{padding:"20px 0",display:'flex',justifyContent:'center',alignItems: 'center',flexWrap:"wrap"}}>

                        {this.renderPackages()}

                    </div>
                </div>
            </div>
            )
        
    }
}


const mapStateToProps = (state) => {
    return ({ tokens: state.auth.tokens })
}
export default connect(mapStateToProps, { logout })(ListPackages);