import React, { Component } from "react";
import { ChoosePhotoWidget, ProfileCardWidget } from "../../../components/Widgets";
import { Col, Row, Card, Form, Button, InputGroup, Spinner } from '@themesberg/react-bootstrap';

import { Multiselect } from 'multiselect-react-dropdown';

import axios from "../../../api";
import Profile3 from "../../../assets/img/team/profile-picture-3.jpg";
import history from "../../../history";
import { connect } from "react-redux";
import {logout} from "../../../actions"
import Loader from "../helpers/Loader"
class EditPackages extends Component {
    state = {
        price: 0,
        description: null,
        paymentStatus: "free",
        packageEditLoader: false,
        title: "",
        picture: null,
        category: [],
        defaultPic: null,
        loader: false,
    }
    componentDidMount() {
        this.fetchPackage()
    }
    fetchPackage = async () => {
        try {
            this.setState({ loader: true })
            const res = (await axios.get(`/v1/packages/${this.props.match.params.id}`, {
                headers: {
                  Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                }
              })).data.data;
              console.log(res.data)
            const { price, description, paymentStatus, title, picture, category } = res.data
            this.setState({ loader: false, price, description, paymentStatus, title, defaultPic: picture, category: this.props.category.filter(el => category.indexOf(el.id) != -1) })
        } catch (err) {
            if(err.response&&(err.response.status==401||err.response.status==403)){
                this.props.logout(String(window.location.href));
            }
            this.setState({ loader: false })
            console.log(err);
        }

    }

    displayImage = (img) => `data:image/png;base64,${img}`
    onSelect = (selectedItem) => {
        console.log(selectedItem);
        this.setState({ category: selectedItem })
    }

    onRemove = (selectedItem) => {
        this.setState({ category: selectedItem })
        console.log(this.state.category);
    }

    editPackage = async () => {
        const { paymentStatus, price, description, title, picture, category } = this.state
        try {
            this.setState({ packageEditLoader: true })

            var formData = new FormData();
            formData.append("price", price);
            formData.append("description", description);
            if (picture) {
                formData.append("picture", picture);
            }
            formData.append("paymentStatus", paymentStatus);
            formData.append("title", title);
            category.forEach(category => {
                formData.append("category", category.id);
            })
            const res = await axios.patch(`/v1/packages/${this.props.match.params.id}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                }
              })
            console.log(res.data.data);
            this.setState({ packageEditLoader: false })

            history.push("/components/listpackages")
        } catch (err) {
            this.setState({ packageEditLoader: false })
            if(err.response&&(err.response.status==401||err.response.status==403)){
                this.props.logout(String(window.location.href));
            }
            console.log(err);
        }

    }
    OnPhotoSelect = (event) => {
        console.log(event.target.files[0])
        this.setState({ picture: event.target.files[0] })
    }
    // showSpinner = () => {
    //     if (this.state.packageEditLoader) {
    //         return (<Spinner animation="border"
    //             role="status"
    //             style={
    //                 { margin: "0 3rem" }
    //             } >
    //             <span className="sr-only" > Loading... < /span> < /Spinner >
    //         )}
    //         else {
    //         return "Loading..."
    //     }

    // }

    onSave = async(e) => {

        e.preventDefault()
        console.log(this.state);
        if( !this.state.title || !this.state.description || this.state.category.length ===0){
            alert('Please enter required fields!')
        }else{
            if(parseInt(this.state.price) <=0 && this.state.paymentStatus ==="paid"){
                alert("price should not be negative or zero")
            }else if(this.state.picture){
                if((this.state.picture.type === "image/jpeg" || this.state.picture.type === "image/gif" || this.state.picture.type === "image/png") && this.state.picture.size <= 800000){
                    await this.editPackage();
            
                    history.push(`/components/listpackages`)
                }else{
                    alert("uploaded image should be of gif,jpg or png format and should be less than 800kb size")

                }
            }else if( !this.state.picture && this.state.defaultPic){
                await this.editPackage();
            
                history.push(`/components/listpackages`)
            }
            else{
                alert("Something wen't wrong")
            }
        }
    }
    render() {

                        console.log(this.props?.tokens?.access?.token);
        // console.log(this.state.package);
        return this.state.loader?<div className="d-flex justify-content-center align-items-center"><Loader/></div>:(                        
             <>
             {this.state.packageEditLoader&&<span className="loading">Loading...</span>}                                                
                        <Row>
                            <Col xs={12} >
                                <Card border="light" className="bg-white shadow-sm mb-4" >
                                    <Card.Body >
                                        <h5 className="mb-4" > Edit Package </h5>
                                        <Form onSubmit={this.onSave} >
                                            <Row >
                                                <Col sm={3} className="mb-3" >
                                                    <Form.Group id="title" >
                                                        <Form.Label > Title </Form.Label>
                                                        <Form.Control onInput={
                                                            (e) => this.setState({ title: e.target.value })
                                                        }
                                                            value={this.state.title}
                                                            required type="text"
                                                            placeholder="Enter Package Title" />
                                                    </Form.Group>
                                                </Col >
                                                <Col md={9} className="mb-3" >
                                                    <Form.Group id="description" >
                                                        <Form.Label > Description </Form.Label>
                                                        <Form.Control onInput={
                                                            (e) => this.setState({ description: e.target.value })
                                                        }
                                                            value={this.state.description}
                                                            required type="text"
                                                            placeholder="Enter Package description" />
                                                    </Form.Group>
                                                </Col>
                                            </Row >
                                            <Row>
                                                <Col sm={6} className="mb-3" >
                                                    <Form.Group id="paymentStatus" >
                                                        <Form.Label > Payment Status </Form.Label>
                                                        <Form.Select onInput={
                                                            (e) => {
                                                                if(e.target.value === "free"){
                                                                    this.setState({ price: 0 })
                                                                }
                                                                this.setState({ paymentStatus: e.target.value })}}
                                                            value={this.state.paymentStatus}
                                                        >
                                                            <option value="free" > Free </option>
                                                            <option value="paid" > Paid </option>
                                                        </Form.Select >
                                                    </Form.Group>
                                                </Col >
                                                <Col md={6} className="mb-3" >
                                                {this.state.paymentStatus=="paid"? 
                                                    <Form.Group id="price" >
                                                        <Form.Label > Price </Form.Label>
                                                        <Form.Control
                                                            value={this.state.price}
                                                            onInput={
                                                                (e) => this.setState({ price: e.target.value })}
                                                            required type="number"
                                                            placeholder="0" />
                                                    </Form.Group>
                                                    :""
                                                }
                                                </Col >
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <Form.Group id="category">
                                                        <Form.Label>Category</Form.Label>
                                                        <Multiselect

                                                            options={this.props.category}
                                                            placeholder="Select Category"
                                                            selectedValues={this.state.category}
                                                            onSelect={this.onSelect} // Function will trigger on select event
                                                            onRemove={this.onRemove} // Function will trigger on remove event
                                                            displayValue="categoryName" // Property name to display in the dropdown options
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <ChoosePhotoWidget
                                                        title="Select package picture"
                                                        photo={this.state.picture ? URL.createObjectURL(this.state.picture) : this.displayImage(this.state.defaultPic)}
                                                        OnPhotoSelect={this.OnPhotoSelect}
                                                    />
                                                </Col>
                                            </Row>
                                            <div className="mt-3" >
                                                <Button variant="primary" type="submit" >Edit Package </Button>

                                                {/* <Button variant="primary"
                                                    type="submit" > {this.state.packageEditLoader ? this.showSpinner() : "Edit Package"} </Button> */}
                                            </div>

                                        </Form>
                                    </Card.Body >
                                </Card>
                            </Col>
                        </Row>
                    </>
        );
    }
}


const mapStateToProps = (state) => {
    return ({tokens: state.auth.tokens ,category:state.current.category})
}
export default connect(mapStateToProps,{logout})(EditPackages)