import React from "react";
import { faCashRegister, faUser } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from '@themesberg/react-bootstrap';
import { connect } from "react-redux";
import { CounterWidget } from "../../components/Widgets";
import axios from "../../api";
import {logout} from "../../actions"
import Loader from "../../components/Loader";
import { Link } from 'react-router-dom';
function DashboardOverview(props) {
    const [customerCount,setCustomerCount]=React.useState(0)
    const [packageCount,setPackageCount] = React.useState(0)
    const [hairstyleCount,setHairstyleCount] = React.useState(0)
    const [customerLoader,setCustomerLoader] = React.useState(false)
    const [packageLoader,setPackageLoader] = React.useState(false)
    const [hairstyleLoader,setHairstyleLoader] =React.useState(false)


    React.useEffect(()=>{
      setHairstyleLoader(true)
      setCustomerLoader(true)
      setPackageLoader(true)
      axios.get(`/v1/hairstyles/page/0/5`, {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`
        }
        })
        .then(res=>{
          setHairstyleCount(res.data.data.count)
          setHairstyleLoader(false)
      })
        .catch(err=>{
          setHairstyleLoader(false)
          console.log(err)
          if(err.response&&(err.response.status==401||err.response.status==403)){
            props.logout(String(window.location.href));
        }
  
        })

      axios.get("/v1/users?role=user", {
        headers: {
            Authorization: `Bearer ${props?.tokens?.access?.token}`
        }
      })
      .then((res=>{  
        setCustomerCount(res.data.totalResults)
        setCustomerLoader(false)
      }))
      .catch(err=>{
        console.log(err)
        setCustomerLoader(false)
        if(err.response&&(err.response.status==401||err.response.status==403)){
          props.logout(String(window.location.href));
      }

      });


      axios.get("/v1/packages", {
      headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`
      }
      })
      .then((res=>{
        setPackageCount(res.data.data.result.length)
        setPackageLoader(false)
      }))
      .catch(err=>{
        console.log(err)
        setPackageLoader(false)
        if(err.response&&(err.response.status==401||err.response.status==403)){
          props.logout(String(window.location.href));
      }

      }); 
      
      
          },[])
    

   
return ( <>
            <div className = "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4" > 
             </div>

            <Row className = "justify-content-md-center" >  <Col xs = { 12 }
            sm = { 6 }
            xl = { 4 }
            className = "mb-4" >
           <Link to="/components/customers"> <CounterWidget category = "Customers"
            title = {customerLoader? <Loader/>:customerCount }
            icon = { faUser }
            iconColor = "shape-secondary" />
            </Link>
            </Col>

            <Col xs = { 12 }
            sm = { 6 }
            xl = { 4 }
            className = "mb-4" >
            <Link to="/components/listpackages"><CounterWidget category = "Packages"
            title = {packageLoader?<Loader/>:packageCount }
            icon = { faCashRegister }
            iconColor = "shape-tertiary" />
            </Link>
            </Col>

            <Col xs = { 12 }
            sm = { 6 }
            xl = { 4 }
            className = "mb-4" >
            <Link to="/components/listallhairstyles"><CounterWidget category = "HairStyles"
            title = { hairstyleLoader?<Loader/>:hairstyleCount }
            icon = { faCashRegister }
            iconColor = "shape-tertiary" />
            </Link>
            </Col> </Row >

             </>
        );
    
}
const mapStateToProps = (state) => {
    return ({ tokens: state.auth.tokens })
}
export default connect(mapStateToProps,{logout})(DashboardOverview);