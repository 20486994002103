
import React from "react";
import { Field,reduxForm } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import "./Signin.css";
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { login } from "../../actions";
import LoadImg from "../../components/SmallLoader"
const renderInput=({input,icon,label,placeholder,meta})=>{  
  const isError =meta.touched&&meta.error
  return(
    
  <div id={input.name} style={{textAlign:'left !important'}} >
    <Form.Label > {label}</Form.Label>
    <InputGroup>
      <InputGroup.Text>
        <FontAwesomeIcon icon={icon} />
      </InputGroup.Text>
      <Form.Control {...input} required type={input.name==="password"?input.name : "text"} placeholder={placeholder}/>
    </InputGroup>
    <div style={{color:'red'}}>{isError?meta.error: null}</div>
  </div>
  )
}

const Signin= (props) => {
  const submit=(formProps)=>{
    console.log(formProps);
    const {username,password}=formProps
    console.log(props);
    props.login({username,password})
}
  return (
      <>
      <div className="d-flex justify-content-center">
      <Row>
        <div className="heading1">
          Hairstyle
      </div>

<div style={{margin:'100px 0' , border:'3px black solid', padding:'60px' , borderRadius:'20px'}}>
  <div className="heading2"> 
    Login
    </div>
  <Form onSubmit={props.handleSubmit(submit)} >               
                 <Field name="username" label="username" placeholder="jane@example.com" icon={faEnvelope} component={renderInput}/>
                 <Field name="password" label="password" placeholder="password" icon={faUnlockAlt} component={renderInput}/>
                  <Form.Group>
                   <div className="text-end">
                     {/* <Form.Check type="checkbox">
                       <FormCheck.Input id="defaultCheck5" className="me-2" />
                       <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                     </Form.Check> */}
                     <Card.Link as={Link} to={Routes.ForgotPassword.path}  className="small">forgot password?</Card.Link>
                   </div>
                 </Form.Group>
                 <div className="text-center" style={{margin:'10px 0'}} >
                   <div style={{width:'200px',display:'inline-block'}} >
                 {props.loading? <div className="d-flex justify-content-center align-items-center"><LoadImg/></div> :<Button variant="primary" type="submit" className="w-100">
                   Login
                 </Button>}
                   </div>
                </div>
                
                <div className="text-center" style={{margin:'10px 0'}} >
                   {/* <div style={{width:'200px',display:'inline-block'}} >
                     <div style={{display:'flex',justifyContent:'space-between'}}>
                     <a href="http://localhost:8000/v1/auth/facebook" variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon className="icon" icon={faFacebookF} />
                  </a>
                  <a href="http://localhost:8000/v1/auth/google" variant="outline-light" className="btn-icon-only btn-pill text-google me-2">
                    <FontAwesomeIcon className="icon" icon={faGoogle} />
                  </a>
                  </div>
                   </div> */}
                   <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
                </div>

               </Form>
        </div> 
      </Row>
      
       
      </div>
      </>
  
  )


//   return (
//     <main>
//       <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
//         <Container>
//           <p className="text-center">
//             <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
//               <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
//             </Card.Link>
//           </p>
//           <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
//             <Col xs={12} className="d-flex align-items-center justify-content-center">
//               <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
//                 <div className="text-center text-md-center mb-4 mt-md-0">
//                   <h3 className="mb-0">Sign in to our platform</h3>
//                 </div>
                // <Form onSubmit={props.handleSubmit(submit)} className="mt-4">
                 
                //   <Field name="username" label="Your Email or Phone" placeholder="email/phone" icon={faEnvelope} component={renderInput}/>
                //   <Field name="password" label="Your Password" placeholder="Password" icon={faUnlockAlt} component={renderInput}/>
                //    <Form.Group>
                //     <div className="d-flex justify-content-between align-items-center mb-4">
                //       <Form.Check type="checkbox">
                //         <FormCheck.Input id="defaultCheck5" className="me-2" />
                //         <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                //       </Form.Check>
                //       <Card.Link className="small text-end">Lost password?</Card.Link>
                //     </div>
                //   </Form.Group>
                //   <Button variant="primary" type="submit" className="w-100">
                //     Sign in
                //   </Button>
                // </Form>

//                 <div className="mt-3 mb-4 text-center">
//                   <span className="fw-normal">or login with</span>
//                 </div>
//                 <div className="d-flex justify-content-center my-4">
                 
                  
                  // <a href="http://localhost:8000/v1/auth/facebook" variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                  //   <FontAwesomeIcon icon={faFacebookF} />
                  // </a>
                  // <a href="http://localhost:8000/v1/auth/google" variant="outline-light" className="btn-icon-only btn-pill text-google me-2">
                  //   <FontAwesomeIcon icon={faGoogle} />
                  // </a>
                  
//                   <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
//                     <FontAwesomeIcon icon={faGithub} />
//                   </Button>
//                 </div>
                // <div className="d-flex justify-content-center align-items-center mt-4">
                //   <span className="fw-normal">
                //     Not registered?
                //     <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                //       {` Create account `}
                //     </Card.Link>
                //   </span>
                // </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </main>
  // );
};

const validate=formValues=>{
  const errors={};

  if(!formValues.username){
      errors.username="Must have a username"
  }
  if(!formValues.password){
      errors.password="Must have a password"
  }else if(formValues.password.length < 6){
      errors.password="Must contains atleast 6 characters"
  }
  return errors
}
const mapStateToProps=(state)=>{
  return ({loading:state.auth.loading})
}
const SigninForm= reduxForm({
  form:'signupForm',
  validate
})(Signin)

export default connect(mapStateToProps,{login})(SigninForm)