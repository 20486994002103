import {
    LOGIN_USER,
    REGISTER_USER,
    CHECK_LOGIN,
    AUTH_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SETLOADING,
    SETLOADINGFALSE
} from '../actions/types';
  

export default function(state={
    loading: false
},action){
    switch(action.type){
        case SETLOADINGFALSE:
            return {...state,loading:false}
        case SETLOADING:
            return {...state,loading:true}
        case LOGIN_USER:
        case REGISTER_USER:
        case AUTH_USER:
            return { ...state, ...action.payload} 
        case FORGOT_PASSWORD:
        case RESET_PASSWORD:
            return {...state}
        
        case LOGOUT_USER:
            console.log("Logout");
            return { ...state,user:null,tokens:null }
        default:
            return state;
    }
}

