import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";
import DemoOrder from './DemoOrder';
// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import CreateHairStyles from "./components/Hairstyles/CreateHairStyles"
import CreateModels from "./components/Models/CreateModels"
import ListModels from "./components/Models/ListModels"
import EditModels from "./components/Models/EditModels"
import CreateTutorials from "./components/Tutorials/CreateTutorials"
import ListTutorials from "./components/Tutorials/ListTutorials"
import EditTutorials from "./components/Tutorials/EditTutorials"
import CreatePackages from "./components/Hairstyles/CreatePackage"

import ListHairStyles from "./components/Hairstyles/ListHairStyles"
import EditHairStyles from "./components/Hairstyles/EditHairStyles"
import ListPackages from "./components/Hairstyles/ListPackages"
import EditPackages from "./components/Hairstyles/EditPackage"
import ListAllHairStyles from "./components/Hairstyles/ListAllHairstyles"

import AddColors from "./components/Colors/AddColors"

import Customers from "./components/Customers/Customers"
import history from "../history";
import { connect } from 'react-redux';
import { setCurrentLoggedIn ,logout,setCategorys} from '../actions';
import axios from "../api"
const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
   
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};



const HomePage= (props) =>{ 
  let currentUser= JSON.parse(localStorage.getItem("auth"))||{};
//   const fetchTokensAndUser=async(id,rToken)=>{
//     let token=rToken
//     if(rToken.includes("#_=_")&&rToken.split("#_=_")[1]==""){
//       token=rToken.split("#_=_")[0]
//     }
//     let res=await axios.post("/v1/auth/refresh-tokens",{
//       refreshToken:token
//     });
//     const tokens=res.data;
//      res=await axios.get(`/v1/users/${id}`,{headers:{Authorization:`Bearer ${tokens.access.token}`}})
//       const user=res.data;
//       currentUser={user,tokens}
//       console.log(currentUser);
//     props.setCurrentLoggedIn({user,tokens})

//   history.push("/")
//   window.location.reload()
//   }
//  if(window.location.href.includes("/?auth=")){
//   const authParams= window.location.href.split("/?auth=")[1];
//   const [userId,refreshToken]=authParams.split("&&");
//   fetchTokensAndUser(userId,refreshToken);

//  }
//   else 
  if(!currentUser.user || new Date(currentUser.tokens.access.expires).getTime() < new Date().getTime()){
    props.logout()
    history.push("/examples/sign-in");
}
console.log(currentUser);
props.setCategorys();
props.setCurrentLoggedIn(currentUser)
  // const[isLoggedIn,setIsLoggedIn]=useState(null)

 
  return(

  <Switch>
    {/* <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} /> */}
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.DemoPackage.path} component={DemoOrder} />


    {/* components */}
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />
    <RouteWithSidebar exact path={Routes.CreateHairStyles.path} component={CreateHairStyles} />
    <RouteWithSidebar exact path={Routes.CreatePackages.path} component={CreatePackages} />
    <RouteWithSidebar exact path={Routes.EditPackages.path} component={EditPackages} />
    <RouteWithSidebar exact path={Routes.ListHairStyles.path} component={ListHairStyles} />
    <RouteWithSidebar exact path={Routes.EditHairStyles.path} component={EditHairStyles} />
    <RouteWithSidebar exact path={Routes.ListPackages.path} component={ListPackages} />
    <RouteWithSidebar exact path={Routes.ListModels.path} component={ListModels} />
    <RouteWithSidebar exact path={Routes.EditModels.path} component={EditModels} />
    <RouteWithSidebar exact path={Routes.Customers.path} component={Customers} />
    <RouteWithSidebar exact path={Routes.CreateModels.path} component={CreateModels} />
    <RouteWithSidebar exact path={Routes.CreateTutorials.path} component={CreateTutorials} />    
    <RouteWithSidebar exact path={Routes.ListTutorials.path} component={ListTutorials} />
    <RouteWithSidebar exact path={Routes.EditTutorials.path} component={EditTutorials} />    
    <RouteWithSidebar exact path={Routes.AddColors.path} component={AddColors} />
    <RouteWithSidebar exact path={Routes.ListAllPackages.path} component={ListAllHairStyles} />




    {/* documentation */}
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
}


export default connect(null,{setCurrentLoggedIn,logout,setCategorys})(HomePage)