import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import { TransactionsTable } from "../components/Tables";
import axios from "../api";
import { connect } from "react-redux";
import {logout} from "../actions";
const Transaction= (props) => {  
  useEffect(()=>{
    fetchTransactions()
  },[])
  const [transactions,setTransactions]=useState([])
  const [colorLoader,setColorLoader]=useState(false)
  
  const fetchTransactions=async()=>{
    setColorLoader(true)
    try{
    const {data:{data:{docs}}}=await axios.get("/v1/transactions",{
      headers:{
        Authorization:`Bearer ${props?.tokens?.access?.token}`
      }
    })
    console.log("docs",docs)
    docs.forEach(el=>{
      console.log("el",el)
      el.title=el.description;
      if(el.user!=null){
        el.email=el.user.email;
      }      
      if(!el.price){
        el.price=el.price
      }
    })
    console.log(docs);    
    setTransactions(docs)
    setColorLoader(false)

    }catch(err){
      console.log(err);
      if(err.response&&(err.response.status==401||err.response.status==403)){
        props.logout(String(window.location.href));
    }
    }
  }
  return (
    <>
    {colorLoader&&<span className="loading">Loading...</span>}
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Volt</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Transactions</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/* <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup> */}
        </div>
      </div>

    

      <TransactionsTable transactions={transactions} />
    </>
  );
};

const mapStateToProps=state=>{
  return({tokens:state.auth.tokens})
}
export default connect(mapStateToProps,{logout})(Transaction)
