import axios from '../api';
import {
    LOGIN_USER,
    REGISTER_USER,
    AUTH_USER,
    LOGOUT_USER,
    CHECK_LOGIN,
    SETLOADING,
    CATEGORYS,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SETLOADINGFALSE
} from './types';
import history from '../history'

// export function registerUser(dataToSubmit){
//     const request = axios.post(`${USER_SERVER}/register`,dataToSubmit)
//         .then(response => response.data);
    
//     return {
//         type: REGISTER_USER,
//         payload: request
//     }
// }

// export function loginUser(dataToSubmit){
//     const request = axios.post(`${USER_SERVER}/login`,dataToSubmit)
//                 .then(response => response.data);

//     return {
//         type: LOGIN_USER,
//         payload: request
//     }
// }

export const login=({username,password})=>async(dispatch)=>{

    dispatch({type:SETLOADING})
    try{
        const response= await axios.post('/v1/auth/login',{username,password})
        console.log("RESPONSE",response.data);
        saveState("auth",response.data);
        dispatch({type:LOGIN_USER,payload:response.data})

    dispatch({type:SETLOADINGFALSE})
        history.push("/")
    }
    catch(err){

    dispatch({type:SETLOADINGFALSE})
        alert(err.response.data.message)
    }
}

export const register=(body)=>async(dispatch)=>{

    dispatch({type:SETLOADING})
    try{

        const response= await axios.post('/v1/auth/register',{...body})
        console.log(response.data);
        saveState("auth",response.data);

    dispatch({type:SETLOADINGFALSE})
        dispatch({type:REGISTER_USER,payload:response.data})
        history.push("/")
    }catch(err){

    dispatch({type:SETLOADINGFALSE})
        alert(err.response.data.message)
    }
}

export const resetPassword=(body,token)=>async(dispatch)=>{
    try{
        const response= await axios.post(`/v1/auth/reset-password?token=${token}`,{...body})
        console.log(response.data);
        // saveState("auth",response.data);
        dispatch({type:RESET_PASSWORD})
        history.push("/examples/sign-in")
    }catch(err){
        alert(err.response.data.message)
    }
}

export const forgotPassword=({email})=>async(dispatch)=>{
    const res=await axios.post('/v1/auth/forgot-password',{email})
    dispatch({type:FORGOT_PASSWORD})
    // history.push("/examples/reset-password")
}

export const setCurrentLoggedIn=(auth)=>{
    saveState("auth",auth)
    // const auth=getSavedState("auth")
    // console.log("This is auth",auth)
    return {type:AUTH_USER,payload:auth}
}

export const setCategorys=()=>async(dispatch)=>{
    const res=(await axios.get('/v1/categorys')).data.data.docs
    console.log("RESS",res);
    saveState("category",res)
    dispatch({type:CATEGORYS,payload:{category:res}})
}

export const logout=()=>{
    window.localStorage.clear()
    history.push("/examples/sign-in")
    return {type:LOGOUT_USER}
}


// export function auth(){
//     const request = axios.get(`${USER_SERVER}/auth`)
//     .then(response => response.data);

//     return {
//         type: AUTH_USER,
//         payload: request
//     }
// }

// export function logoutUser(){
//     const request = axios.get(`${USER_SERVER}/logout`)
//     .then(response => response.data);

//     return {
//         type: LOGOUT_USER,
//         payload: request
//     }
// }

  
  function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
  }

  function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key))
  }