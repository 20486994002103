import React, { Component, useState } from "react";
import { ChoosePhotoWidget } from "../../../components/Widgets";
import { Col, Row, Card, Form, Button, InputGroup, Spinner, Modal } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';
import axios from "../../../api";
import history from "../../../history";
import { connect } from "react-redux";
import { Multiselect } from 'multiselect-react-dropdown';
import { logout } from "../../../actions"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw,convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Grid from "../helpers/Grid"
import "./hairstyle.scss"


class CreateHairStyles extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    allPackages: [],
    filteredPackages: [],
    packages: [],
    availableColors: [],
    name: null,
    number: null,
    description: "Enter Hairstyle description",
    category: [],
    picture: null,
    greyScalePicture: null,
    hairstyleCreateLoader: false,
    multiselectDropdownOptionLoader: true,
    openModal: false,
    packageDescription: "",
    packagePaymentStatus: "free",
    packagePrice: 0,
    packageTitle: "",
    addPackage: null,
    packagePicture: null,
    packageCategory: [],
    packageCreatedLoader: false
  }

  componentDidMount() {
    this.fetchPackages()
  }
  fetchPackages = async () => {
    try {
      const res = await axios.get(`/v1/packages`, {
        headers: {
          Authorization: `Bearer ${this.props?.tokens?.access?.token}`
        }
      })
      this.setState({ allPackages: res.data.data.result, multiselectDropdownOptionLoader: false })
      console.log(this.state.allPackages);
    } catch (err) {
      if (err.response && (err.response.status == 401 || err.response.status == 403)) {
        this.props.logout(String(window.location.href));
      }
    }
  }

  checkCategory = (categorys, categoryArr) => categorys ? categorys.some(category => {
    console.log(categoryArr);
    categoryArr.map(el => { console.log(el); return el.id })
    console.log();
    return categoryArr.map(el => el.id).indexOf(category) != -1
  }) : false
  renderPackageOptions = () => this.state.packages.map((pkg, i) => {
    return (
      <option value={JSON.stringify(pkg)}>{pkg.description}</option>
    )
  })

  renderMultiSelectOptions = () => this.state.packages.map((pkg, i) => {
    const options = []
    options.push(pkg.description)

    console.log("options are " + options);
    return options;
  })
  createHairStyle = async () => {    
    // for (var i=0;i<100;i++) {    
    //   var formData = new FormData();
    //   console.log(this.state.packages);
    //   formData.append("name", "Test Hairstyle "+(99+i));
    //   formData.append("description", "Test Hairstyle "+(99+i));
    //   formData.append("picture", pictureData.picture);
    //   formData.append("greyScale", pictureData.greyscale);
    //   formData.append("category", "60aa8819edb5cb18f91b2039");      
    //   formData.append("packages", "617fd747967a110ac99b24f2");    

    // // formData.append("availableColors", "original");
    // // this.state.availableColors.forEach(color => {
    // //   formData.append("availableColors", color);
    // // })
    // try {
    //   const res = await axios.post("/v1/hairstyles", formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       Authorization: `Bearer ${this.props?.tokens?.access?.token}`
    //     }
    //   })
    //   this.setState({ hairstyleCreateLoader: false })

    //   console.log(res.data);
    // }
    // catch (err) {
    //   this.setState({ hairstyleCreateLoader: false })
    //   console.log(err)
    //   if (err.response && (err.response.status == 401 || err.response.status == 403)) {
    //     this.props.logout(String(window.location.href));
    //   }

    // }
    // }
    if(this.state.picture){
      var formData = new FormData();
      console.log(this.state.packages);
      formData.append("name", this.state.name);
      formData.append("number", this.state.number);
      formData.append("description", this.state.description);
      formData.append("picture", this.state.picture);
      formData.append("greyScale", this.state.greyScalePicture);
      this.state.category.forEach(category => {
        formData.append("category", category.id);
      })
      this.state.packages.forEach(pkg => {
        formData.append("packages", pkg._id);
      })
  
      // formData.append("availableColors", "original");
      // this.state.availableColors.forEach(color => {
      //   formData.append("availableColors", color);
      // })
      try {
        const res = await axios.post("/v1/hairstyles", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.props?.tokens?.access?.token}`
          }
        })
        this.setState({ hairstyleCreateLoader: false })
  
        console.log(res.data);
      }
      catch (err) {
        this.setState({ hairstyleCreateLoader: false })
        console.log(err)
        if (err.response && (err.response.status == 401 || err.response.status == 403)) {
          this.props.logout(String(window.location.href));
        }
  
      }
    }else{
      alert("Please fill and upload all data")
    }
   

  }
  setPackagePicture = async (id) => {
    var formData = new FormData();
    formData.append("picture", this.state.picture);

    try {
      const res = await axios.patch(`/v1/packages/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.props?.tokens?.access?.token}`
        }
      })

      console.log(res.data);
      console.log("saved");
    }
    catch (err) {

      console.log(err)
      if (err.response && (err.response.status == 401 || err.response.status == 403)) {
        this.props.logout(String(window.location.href));
      }

    }

  }




  showSpinner = () => {
    if (this.state.hairstyleCreateLoader) {
      return (
        <Spinner animation="border" role="status" style={{ margin: "0 3rem" }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      )
    } else {
      return "Loading..."
    }
  }

  onSave = async (e) => {
    e.preventDefault()
    
    console.log(this.state);
    if(!this.state.number || !this.state.name || !this.state.description || !this.state.greyScalePicture || !this.state.picture || this.state.category.length<=0 ){
      alert("please fill all require data!")
    }else{
      if( ((this.state.greyScalePicture.type === "image/jpeg" || this.state.greyScalePicture.type === "image/gif" || this.state.greyScalePicture.type === "image/png") && this.state.greyScalePicture.size <= 800000) && ((this.state.picture.type === "image/jpeg" || this.state.picture.type === "image/gif" || this.state.picture.type === "image/png") && this.state.picture.size <= 800000)){
        this.setState({ hairstyleCreateLoader: true })
        await this.createHairStyle();
        await Promise.all(this.state.packages.map(async (pkg) => {
          if (!pkg.picture) {
            await this.setPackagePicture(pkg._id)
          }
        }))
        console.log(this.state.packages);
        history.push(`/components/listallhairstyles`)
      }else{
        
        alert("file size should be jpg,png or gif and must be less than 800kb")

      }
    }

  }
  OnPhotoSelect = (event) => {
    console.log(event.target.files[0])
    if(event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/gif" || event.target.files[0].type === "image/png"){
      this.setState({ picture: event.target.files[0] })
    }else{
      alert("uploaded image should be of gif,jpg or png format")
    }
    // if(event.target.files[0]){
    //   var formData = new FormData();
    // formData.append("profile",event.target.files[0])
    // }
  }

  OnGreyScalePhotoSelect = (event) => {
    console.log(event.target.files[0])
    if(event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/gif" || event.target.files[0].type === "image/png"){
      this.setState({greyScalePicture: event.target.files[0] })
    }else{
      alert("uploaded image should be of gif,jpg or png format")
    }
    // if(event.target.files[0]){
    //   var formData = new FormData();
    // formData.append("profile",event.target.files[0])
    // }
  }

  onSavePackage = async () => {
    try {
      this.setState({ packageCreatedLoader: true })

      var formData = new FormData();
      formData.append("price", this.state.packagePrice);
      formData.append("description", this.state.packageDescription);
      formData.append("picture", this.state.packagePicture);
      formData.append("paymentStatus", this.state.packagePaymentStatus);
      formData.append("title", this.state.packageTitle);
      this.state.packageCategory.forEach(category => {
        formData.append("category", category.id);
      })
      const res = await axios.post("/v1/packages", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.props?.tokens?.access?.token}`
        }
      })
      console.log(res.data.data);
      this.setState({ addPackage: res.data.data.result })
      const arr = [...this.state.packages]
      arr.push(this.state.addPackage)

      this.setState({ packages: arr, allPackages: [...this.state.allPackages, this.state.addPackage] })

      this.setState({ packageCreatedLoader: false })
      this.hideModal()
    } catch (err) {
      console.log(err);
      if (err.response && (err.response.status == 401 || err.response.status == 403)) {
        this.props.logout(String(window.location.href));
      }
    }

  }


  onSelectCategory = (selectedItem) => {
    const arr = [...selectedItem]
    this.setState({ category: arr })
    // const filteredPackages=this.state.allPackages.filter(pkg=>this.checkCategory(pkg.category,arr))
    // this.setState({filteredPackages})
  }

  onEditorStateChange = (editorState) => {
    console.log("get current content",convertToRaw(editorState.getCurrentContent()))
    this.setState({
      editorState
    });
    //console.log(convertFromRaw(editorState.getCurrentContent()))
    this.setState({ description: draftToHtml(convertToRaw(editorState.getCurrentContent()))})    
    console.log(this.state.description)
    console.log(this.state.editorState)
  };

  onRemoveCategory = (selectedItem) => {
    const arr = [...selectedItem]
    this.setState({ category: arr })
    // const filteredPackages=this.state.allPackages.filter(pkg=>this.checkCategory(pkg.category,arr))
    // this.setState({filteredPackages})
  }

  onSelect = (selectedItem) => {
    console.log(selectedItem);
    this.setState({ packages: selectedItem })
  }

  onRemove = (selectedItem) => {
    this.setState({ packages: selectedItem })
    console.log(this.state.packages);
  }

  hideModal = () => {
    this.setState({ openModal: false })
    console.log("modal close");
  }

  renderPackageCreateForm = () => {

    return (

      <Row >
        <Col xs={12} >
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body >
              <h5 className="mb-4" > Create Package </h5>
              <Form >
                <Row >

                  <Col md={3} className="mb-3" >
                    <Form.Group id="title" >
                      <Form.Label > Title </Form.Label>
                      <Form.Control onInput={e => this.setState({ packageTitle: e.target.value })} value={this.state.packageTitle} required type="text" placeholder="Enter Package title" />
                    </Form.Group>
                  </Col>
                  <Col sm={9} className="mb-3" >
                    <Form.Group id="description" >
                      <Form.Label > Description </Form.Label>
                      <Form.Control onInput={e => this.setState({ packageDescription: e.target.value })} value={this.state.packageDescription} required type="text" placeholder="Enter Package description" />
                    </Form.Group>
                  </Col >

                </Row >
                <Row >
                  <Col sm={6} className="mb-3" >
                    <Form.Group id="paymentStatus" >
                      <Form.Label > Payment Status </Form.Label>
                      <Form.Select onInput={
                        (e) => this.setState({ packagePaymentStatus: e.target.value })} value={this.state.packagePaymentStatus}>
                        <option value="free" > Free </option>
                        <option value="paid" > Paid </option>
                      </Form.Select >
                    </Form.Group>
                  </Col >
                  <Col md={6} className="mb-3" >
                    <Form.Group id="price" >
                      <Form.Label > Price </Form.Label>
                      <Form.Control onInput={
                        (e) => this.setState({ packagePrice: e.target.value })} value={this.state.packagePrice}
                        required type="number" placeholder="0" />
                    </Form.Group>
                  </Col >
                </Row>
                <Row>
                  <Col xs={6}>
                    <Form.Group id="category">
                      <Form.Label>Category</Form.Label>
                      <Multiselect

                        options={this.props.category}
                        placeholder="Select Category"
                        selectedValues={this.state.packageCategory}
                        onSelect={(item) => this.setState({ packageCategory: item })} // Function will trigger on select event
                        onRemove={(item) => this.setState({ packageCategory: item })} // Function will trigger on remove event
                        displayValue="categoryName" // Property name to display in the dropdown options
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                  <div style={{overflow:"hidden"}}>
                    <ChoosePhotoWidget
                      title="Select package picture"
                      photo={this.state.packagePicture ? URL.createObjectURL(this.state.packagePicture) : null}
                      OnPhotoSelect={e => this.setState({ packagePicture: e.target.files[0] })}
                    />
                    </div>
                  </Col>
                </Row>
                <div className="mt-3" >
                  <Button variant="primary" onClick={this.onSavePackage}
                  > {this.state.packageCreatedLoader ? this.showSpinner() : "Create Package"}
                  </Button>
                </div >
              </Form>
            </Card.Body >
          </Card>
        </Col>
      </Row>



    )
  }
  render() {
    const { editorState } = this.state;    
    // console.log(this.state.package);
    return (
      <>

        <Row>
          <Col xs={12} >
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <h5 className="mb-4">Create Hairstyle</h5>
                <Form onSubmit={this.onSave}>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="hairstyleName">
                        <Form.Label>Hairstyle Name</Form.Label>
                        <Form.Control onInput={e => this.setState({ name: e.target.value })} value={this.state.name} required type="text" placeholder="Enter the hairstyle name" />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="category">
                        <Form.Label>Category</Form.Label>
                        <Multiselect
                          singleSelect={true}
                          options={this.props.category}
                          placeholder="Select Category"
                          selectedValues={this.state.category}
                          onSelect={this.onSelectCategory} // Function will trigger on select event
                          onRemove={this.onRemoveCategory} // Function will trigger on remove event
                          displayValue="categoryName" // Property name to display in the dropdown options
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} className="mb-3">
                      <Form.Group id="packages">
                        <Form.Label>Packages</Form.Label>
                        <Multiselect

                          options={this.state.allPackages}
                          placeholder="Select Package"
                          loading={this.state.multiselectDropdownOptionLoader}
                          selectedValues={this.state.packages}
                          onSelect={this.onSelect} // Function will trigger on select event
                          onRemove={this.onRemove} // Function will trigger on remove event
                          displayValue="description" // Property name to display in the dropdown options
                        />
                        {/* <Form.Select  defaultValue={JSON.stringify(this.state.packages[0])} onInput={(e)=>this.setState({package:JSON.parse(e.target.value)})}>
                  {this.renderPackageOptions()}
                </Form.Select>
                */}
                        <Button variant="outlined" className="btn btn-outline-success mt-3" onClick={() => this.setState({ openModal: true })}>Create New Package</Button>
                        {/* modal */}
                        <Modal show={this.state.openModal} onHide={this.hideModal} size="xl" backdrop="static" keyboard={false}>
                          <Modal.Header closeButton>
                            <Modal.Title>Create New Package</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <>
                              {this.renderPackageCreateForm()}

                            </>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={this.hideModal}>
                              Close
                    </Button>
                            {/* <Button  variant="primary" onClick={this.hideModal}>
                      Save Changes
                    </Button> */}
                          </Modal.Footer>
                        </Modal>





                      </Form.Group>
                    </Col>
                    {/* <Col md={6} className="mb-3">
                      <Form.Group id="availableColors">
                        <Form.Label>Colors</Form.Label>
                        <Form.Control onInput={(e) => this.setState({ availableColors: e.target.value.split(" ") })} value={this.state.availableColors.join(" ")} required type="text" placeholder="blue pink" />
                      </Form.Group>
                    </Col> */}
                     <Col md={6} className="mb-3">
                      <Form.Group id="hairstyleName">
                        <Form.Label>Hairstyle Number</Form.Label>
                        <Form.Control onInput={e => this.setState({ number: e.target.value })} value={this.state.number} required type="text" placeholder="Enter the hairstyle number" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={9} className="mb-3">
                      <Form.Group id="description">
                        <Form.Label>Description</Form.Label>
                        {/* <Form.Control onInput={(e) => this.setState({ description: e.target.value })} value={this.state.description} required type="text" placeholder="Enter hairstyle description" /> */}
                      <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        onEditorStateChange={this.onEditorStateChange}
                      />

                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div style={{overflow:"hidden"}}>
                      <ChoosePhotoWidget
                        title="Select hairstyle picture"
                        photo={this.state.picture ? URL.createObjectURL(this.state.picture) : null}
                        OnPhotoSelect={this.OnPhotoSelect}
                      />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div style={{overflow:"hidden"}}>
                      <ChoosePhotoWidget
                        title="Select grey scale picture"
                        photo={this.state.greyScalePicture ? URL.createObjectURL(this.state.greyScalePicture) : null}
                        OnPhotoSelect={this.OnGreyScalePhotoSelect}
                      />
                      </div>
                    </Col>
                  </Row>  
                  {/* <Row>
                    <Col xs={12}>
                      <ChoosePhotoWidget
                        title="Male Model"
                        photo={this.state.greyScalePicture ? URL.createObjectURL(this.state.greyScalePicture) : null}
                        //OnPhotoSelect={this.OnGreyScalePhotoSelect}
                      />
                    </Col>
                  </Row>                             */}
                  {this.state.hairstyleCreateLoader&&<span className="loading">Loading...</span>}        
                  <div className="mt-3">
                  <Button variant="primary" type="submit" >Create Hairstyle</Button>
                    {/* <Button variant="primary" type="submit" >{this.state.hairstyleCreateLoader ? this.showSpinner() : "Create Hairstyle"}</Button> */}
                  </div>

                </Form>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </>
    );
  }
};
const mapStateToProps = (state) => {
  return ({ tokens: state.auth.tokens, category: state.current.category })
}
export default connect(mapStateToProps, { logout })(CreateHairStyles)