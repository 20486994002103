import {
    CATEGORYS
} from '../actions/types';
  

export default function(state={},action){
    switch(action.type){
        case CATEGORYS:
            return { ...state, ...action.payload} 
        default:
            return state;
    }
}

