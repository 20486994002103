import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import "./gridstyle.module.css"
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import axios from "../../../api";
import { Routes } from "../../../routes";
import history from "../../../history";
import {connect} from "react-redux"
import {logout} from "../../../actions"

const useStyles = makeStyles((theme) => {
  const maxSm = theme.breakpoints.down('sm');
  
  return {
  
  root: {
    flexGrow: 1,
  },
  parentGrid:{
    display:"grid",
    gridTemplateColumns:"1fr 1fr 1fr",
    justifyContent:"center",
    alignItems:'center',
    justifyItems:"center",
    alignContent:"center",
    gap:"20px 20px",
    [maxSm]:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      flexDirection:"column"
    }
  },
  cardRoot: {
    maxWidth: 345,
  },
  paper: {
    height: 240,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  media: {
    height: 310,
  },

  icon: {
    margin: "3px"
  }
}});
const ReactGrid = (props) => {
  const [spacing, setSpacing] = React.useState(2);
  const [loader, setLoader] = React.useState(false)

  const classes = useStyles();
  const [selectedHairstyles, setSelectedHairstyles] = useState([])
  const displayImage = (img) => `data:image/png;base64,${img}`
  let arr = [...selectedHairstyles];


  const toggle = (item) => {
    const index = arr.findIndex(el => el.id == item.id)
    if (index == -1) {
      arr.push(item)

    } else {
      arr.splice(index, 1)
    }

    setSelectedHairstyles(arr)
    props.selectHairstyle(arr)
  }

  const deleteHairstyle = async (item) => {
    try {
      setLoader(true)
      await axios.delete(`/v1/hairstyles/${item._id}`, {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`
        }
      });
      props.removeHairstyle(item)
      setLoader(false)
      
    } catch (err) {
      setLoader(false)
      if(err.response&&(err.response.status==401||err.response.status==403)){
        props.logout(String(window.location.href));
    }
      console.log(err);
    }
  }

  return (    
    
        <div className={classes.parentGrid}>
          {props.items.map((item) => (
            <div style={{margin:"10px"}}>
              <Card className={classes.cardRoot}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={item.picture ? displayImage(item.picture) : ""}
                    title="Hairstyle Picture"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.name}
                    </Typography>
                  {item.packages.length >0 &&  <p style={{margin:"5px",fontWeight:"bold"}}>
                      {`Included in following packages`}
                    </p>}
                    <ul>
                      {item.packages.length >0 && props.packages.filter(function(n) { return item.packages.indexOf(n._id) !== -1;}).map(el=>{
                            return <li>{el.title}</li>
                        })
                      }
                    </ul>
                    <Typography variant="body2" style={{minWidth:"280px"}} color="textSecondary" component="p" className="card-description-hide">
                      {item.description.replace(/<[^>]+>/g, '')}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>

                  {
                    props.iconText ?
                      <>
                        <Button size="medium" onClick={() => props.parent === "listallhairstyles" ? deleteHairstyle(item) : null} variant="contained" color="secondary" >
                          {props.parent === "listallhairstyles" ? <DeleteIcon className={classes.icon}> </DeleteIcon> : ""} {props.iconText}
                        </Button>
                        <Button size="medium" onClick={() => props.parent === "listallhairstyles" ? history.push(`/components/editHairstyles/${item._id}`) : null} variant="contained" color="secondary" >
                          {props.parent === "listallhairstyles" ? <EditIcon className={classes.icon}> </EditIcon> : ""} Edit
                    </Button>
                      </> : <Checkbox onClick={() => toggle(item)}></Checkbox>}
                </CardActions>
              </Card>
            </div>
          ))}
        </div>
    
  )
}
const mapStateToProps = (state) => {
  return ({ tokens: state.auth.tokens })
}
export default connect(mapStateToProps,{logout})(ReactGrid)