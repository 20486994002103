export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const AUTH_USER = 'AUTH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CATEGORYS = 'CATEGORYS';
export const SETLOADING = "SETLOADING";
export const SETLOADINGFALSE = "SETLOADINGFALSE";

