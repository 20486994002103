import { Button,CircularProgress } from '@material-ui/core'
import React from 'react'
import axios from "../api";

function DemoOrder(){
   const [initialData,setInitialData] = React.useState([])
    const [loading,setLoading] = React.useState(false)
   async function getPackageHairstyles(){
    setLoading(true)
       let data = await axios.get(`/v1/packages/get-demo-package`)
       if(data.status === 200){
        setInitialData(data.data)
       }
       setLoading(false)
   }
   React.useEffect(()=>{
    getPackageHairstyles()

   },[])
    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
        {loading?<CircularProgress/>:initialData.map((el,index)=>{
            return(
                <div key={el.id} style={{display:"flex"}}>
                    <img src={el.img} alt="" width="50"/>
                    <input id={el.id} placeholder={`default index ${index}`} type="number" onChange={(e)=>{
                        let obj =initialData.findIndex((elm)=>elm.id === el.id)
                        initialData[obj].position = parseInt(e.target.value)
                        
                    }}/>

                </div>
            )
        })}

        <Button variant="primary" type="submit" style={{background:"black",color:"white",marginTop:"10px"}} onClick={async ()=>{
            let boolCheck = false

            initialData.forEach((el)=>{
                if(el.position === ""){
                    boolCheck = true
                }
            })

            if(boolCheck){
                alert("Please provide index for all ")
            }else{
                console.log(initialData)
                initialData.sort((a, b) => a.position - b.position)
                let finalIdinitialData = []
                initialData.forEach(el=>finalIdinitialData.push(el.id))
                console.log(finalIdinitialData)
                const res = await axios.post(`/v1/packages/update-package`, finalIdinitialData, { })
                    if(res?.status){
                        alert("updated")
                        window.location.reload()
                    }
            }
        }}>Save</Button>
        </div>

    )
}

export default DemoOrder