import React,{useEffect} from "react"
import axios from "../../../api"

import Loader from "../../../components/Loader"
import Grid from "../helpers/Grid"
import{connect} from "react-redux"
import {logout} from "../../../actions"
import ReactPaginate from 'react-paginate';
import "./hairstyle.scss";
import { Row, Col, Nav, Form,Button, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen,faAngleRight,faAngleLeft, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
function ListAllHairstyles(props) {
    const [hairStyles, setHairStyles] = React.useState([]);
    const [loader,setLoader] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0);
    const [hairStyleCount, setHairStyleCount] = React.useState(0);
    const [itemsPerPage, setItemsPerPage] = React.useState(8);
    const [itemOffset, setItemOffset] = React.useState(0);
    const [endOffset, setEndOffset] = React.useState(8);  
    const [searchedName, setsearchedName] = React.useState(""); 
    const [packages,setPackages] = React.useState([]) 
    useEffect(()=>{
      fetchPackages()
         fetchHairstyles()
  },[]) 
  const fetchPackages = async () => {
    try {
      const res = await axios.get(`/v1/packages`, {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`
        }
      })
      setPackages(res.data.data.result)
      console.log(res.data.data.result)
    } catch (err) {
      if (err.response && (err.response.status == 401 || err.response.status == 403)) {
        this.props.logout(String(window.location.href));
      }
    }
  }
  const searchName = async (event) =>{
    event.preventDefault();
    try{  
      setLoader(true)
      let res = []
      if(searchedName !== ""){
        res=(await axios.get(`/v1/hairstyles/page/0/8?name=`+searchedName, {
                headers: {
                  Authorization: `Bearer ${props?.tokens?.access?.token}`
                }
              })).data.data
      }
      else{
        res=(await axios.get(`/v1/hairstyles/page/0/8`,{
                headers: {
                  Authorization: `Bearer ${props?.tokens?.access?.token}`
                }
              })).data.data        
      }
      
      
      console.log(res)
      console.log(res.result)          
      console.log(res.count)          
      setHairStyles(res.result)  
      setHairStyleCount(res.count)
      setPageCount(Math.ceil(res.count / itemsPerPage));                                
      setLoader(false)          
    }catch(err){
      setLoader(false)
      if(err.response&&(err.response.status==401||err.response.status==403)){
        props.logout(String(window.location.href));
    }

    console.log(err);
    }    
  }
  const removeHairstyle=(hairstyle)=>{
    console.log(hairstyle._id)
    fetchHairstyles()
    const arr=hairStyles.filter(hairStyle=>hairStyle.id!=hairstyle.id)
    setHairStyles(arr)
  }
  const handlePageClick = async (event) => {
    setsearchedName("")
    setLoader(true)
    console.log("Handle page",event.selected,itemsPerPage,endOffset,pageCount,hairStyleCount)
    const newOffset = (event.selected * itemsPerPage) % hairStyleCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    try {
      // if (props.tokens) {
      //   props.setTitle("");
      // }
      console.log("token",props.tokens)
      let pkg = ""
      if(searchedName){
        pkg = (
          await axios.get(`/v1/hairstyles/page/${Number(newOffset)}/${Number(newOffset)+8}?name=${searchedName}`, {
            headers: {
              Authorization: `Bearer ${props?.tokens?.access?.token}`,
            },
          })
        ).data.data
      }else{
         pkg = (
          await axios.get(`/v1/hairstyles/page/${Number(newOffset)}/${Number(newOffset)+8}`, {
            headers: {
              Authorization: `Bearer ${props?.tokens?.access?.token}`,
            },
          })
        ).data.data
      }

      setHairStyles(pkg.result)  
      setHairStyleCount(pkg.count)
      setPageCount(Math.ceil(pkg.count / itemsPerPage));                                
      let array = []      

      // if (props.tokens) {
      //   props.setTitle(pkg.title);        
      // }
      setLoader(false)
      const arr=[]
      const arrNew=[]      
      
      
    } catch (err) {
      //alert("err");
      console.log(err);
      setLoader(false)
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        props.logout(String(window.location.href));
      }
      console.log(err);
    }
    setItemOffset(newOffset);
    setLoader(false)
};
    const fetchHairstyles=async()=>{
        try{  
          setLoader(true)
          const res=(await axios.get(`/v1/hairstyles/page/0/8`, {
            headers: {
              Authorization: `Bearer ${props?.tokens?.access?.token}`
            }
          })).data.data
          console.log(res)
          console.log(res.result)          
          console.log(res.count)          
          setHairStyles(res.result)  
          setHairStyleCount(res.count)
          setPageCount(Math.ceil(res.count / itemsPerPage));                                
          setLoader(false)          
        }catch(err){
          setLoader(false)
          if(err.response&&(err.response.status==401||err.response.status==403)){
            props.logout(String(window.location.href));
        }

        console.log(err);
        }
         
      }
    return (
       <div>                                               
        <div className="d-flex justify-content-center align-items-center">{`Total hairstyles: ${hairStyleCount}`}</div>
      <div style={{margin: "10px"}} className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control   type="text" placeholder="Search" value={searchedName} onChange={(e)=>setsearchedName(e.target.value)} />
                  <button onClick={searchName} style={{background:"rgb(38,43,64)",color:"white",padding:"7px",border:"none",outline:"none",borderRadius:"5px",margin:"0px 10px"}} >
                    Search
                        </button>
                        <button onClick={()=>{
                          setsearchedName("")
                          searchName()
                        }} style={{background:"red",color:"white",padding:"7px",border:"none",outline:"none",borderRadius:"5px",margin:"0px 10px"}} >
                    Clear
                        </button>      
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          {loader?<div className="d-flex justify-content-center align-items-center"><Loader/></div>:<Grid items={hairStyles} packages={packages} removeHairstyle={removeHairstyle} parent="listallhairstyles" iconText="Delete"></Grid>}
      <div className="paginate">
            <ReactPaginate
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={0}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />      
        </div>   
      </div>
    );
  }
  const mapStateToProps = (state) => {
    return ({ tokens: state.auth.tokens })
}
export default connect(mapStateToProps,{logout})(ListAllHairstyles)