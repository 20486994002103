import React from "react"
import ContentLoader from 'react-content-loader'




const Loader =(props)=>{

    const rows = props.rows?props.rows:2
    const columns = props.columns?props.columns:4
    const coverHeight = props.coverHeight?props.coverHeight:385
    const coverWidth = props.coverWidth?props.coverWidth:280
    const padding = props.padding?props.padding:20
    const speed = 1

    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 0
    const covers = Array(columns * rows).fill(2)
    return(
        <ContentLoader
        speed={speed}
        width={columns * coverWidthWithPadding}
        height={rows * coverHeightWithPadding}
        primaryColor="#242b34"
        secondaryColor="#343d4c"
        {...props}
        >
 

{covers.map((g, i) => {
let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
return (
  <rect
    key={i}
    x={vx}
    y={vy}
    rx="0"
    ry="0"
    width={coverWidth}
    height={coverHeight}
  />
)
})}
</ContentLoader>
    )
}

export default Loader;