import React from "react"
import CardUi from "../Cards/CardUI"
import defaultImg from "../../../assets/img/team/profile-picture-1.jpg"
import axios from "../../../api";
import ContentLoader from 'react-content-loader'
import Add from '@material-ui/icons/Add';
import Modal from "../helpers/Modal";
import { connect } from "react-redux";
import { logout } from "../../../actions"
//import {convert} from 'html-to-text'
import ReactPaginate from 'react-paginate';
import { Row, Col, Nav, Form,Button, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen,faAngleRight,faAngleLeft, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import history from "../../../history"

const rows = 2
const columns = 4
const coverHeight = 385
const coverWidth = 330
const padding = 20
const speed = 1

const coverHeightWithPadding = coverHeight + padding
const coverWidthWithPadding = coverWidth + padding
const initial = 0
const covers = Array(columns * rows).fill(2)





class ListHairstyles extends React.Component {



  state = { hairCount:0,activePage:1,itemsPerPage :10,hairstyles: [], isLoading: true, show: false, filteredHairStyles: [], package: null, loader: false,skip:0,limit:6}

  componentDidMount() {
    this.fetchPackage()
    this.fetchHairstyles()
    // this.fetchAllHairstyles()
  }

  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });

  increaseSkip = ()=> this.setState({skip:this.state.skip+6})
  increaseLimit = ()=> this.setState({limit:this.state.limit+6})
  decreaseLimit = ()=> this.setState({limit:this.state.limit-6})
  decreaseSkip = ()=> this.setState({skip:this.state.skip-6})


  fetchPackage = async () => {
    this.setState({loader: true})
    try {
      const res = (await axios.get(`/v1/packages/${this.props.match.params.pkgId}`, {
        headers: {
          Authorization: `Bearer ${this.props?.tokens?.access?.token}`
        }
      })).data.data.data
      console.log("RESSSSSS PACKAGE");
      console.log(res);
      this.setState({ package: res })
      this.setState({loader: false})
    } catch (err) {
      console.log(err);
      if (err.response && (err.response.status == 401 || err.response.status == 403)) {
        this.props.logout(String(window.location.href));
      }

    }
  }

  // fetchAllHairstyles = async () => {
  //   this.setState({loader: true})
  //   try {

  //     const res = await axios.get(`/v1/hairstyles/admin`, {
  //       headers: {
  //         Authorization: `Bearer ${this.props?.tokens?.access?.token}`
  //       }
  //     })
      
  //     const filteredHairStyles = res.data.data.docs.filter(el => this.state.hairstyles.findIndex(h => h.id == el.id) == -1) //.filter(el=>el.category.some(category=>this.state.package.indexOf(category)!=-1))

  //     this.setState({ filteredHairStyles })
  //     console.log(res.data.data.docs);
  //     this.setState({loader: false})


  //   } catch (err) {
  //     if (err.response && (err.response.status == 401 || err.response.status == 403)) {
  //       this.props.logout(String(window.location.href));
  //     }

  //     console.log(err);
  //   }

  // }
  fetchHairstyles = async (val) => {
    if(this?.props?.match?.params?.pkgId){

      try {
    const newOffset =(this.state.hairCount && val)? (val * 7) % this.state.hairCount:0;
    const pkgId = this?.props?.match?.params?.pkgId;
    console.log(`/v1/packages/${pkgId}/page/${Number(val?newOffset:0)}/${Number(val?newOffset+7:7)}`)
      const res = await axios.get(`/v1/packages/${pkgId}/page/${Number(newOffset)}/${Number(newOffset+7)}`, {
        headers: {
          Authorization: `Bearer ${this.props?.tokens?.access?.token}`
        }
      })
      this.setState({ hairstyles: res.data.data.mergedImages.hairstyleInfo })
      this.setState({ hairCount: res.data.data.count })
      console.log(this.state.hairstyles);
      this.setState({ isLoading: false })
    } catch (err) {
      if (err.response && (err.response.status == 401 || err.response.status == 403)) {
        this.props.logout(String(window.location.href));
      }
      console.log(err);
      this.setState({ isLoading: false })
    }
    }
  }
  removeHairstyle = async (hairstyle) => {
    const pkgId = this.props.match.params.pkgId;
    try {
    let res= await axios.patch(`/v1/packages/${pkgId}/removeHairstyle/${hairstyle._id}`, {}, {
        headers: {
          Authorization: `Bearer ${this.props?.tokens?.access?.token}`
        }
      })
      console.log("RESULT",res)
      this.setState({ hairstyles: this.state.hairstyles.filter((h) => h._id != hairstyle._id) })
      const arr = [...this.state.filteredHairStyles]
      arr.push(hairstyle)
      this.setState({ filteredHairStyles: arr });
    } catch (err) {
      if (err.response && (err.response.status == 401 || err.response.status == 403)) {
        this.props.logout(String(window.location.href));
      }
      console.log(err);
    }

  }
  addHairstyles = (hairstyles) => {
    const arr = [...this.state.hairstyles, ...hairstyles]
    this.setState({ hairstyles: arr })
  }
  removeFilteredHairstyles = (hairstyles) => {
    const arr = this.state.filteredHairStyles.filter((el) => hairstyles.findIndex(hairstyle => hairstyle._id == el._id) == -1)
    this.setState({ filteredHairStyles: arr })
  }

  displayImage = (img) => `data:image/png;base64,${img}`

  
  onButtonClick = () => {
    console.log("button is clicked")
  }




  render() {

  const indexOfLastTodo = this.state.activePage * this.state.itemsPerPage;
  const indexOfFirstTodo = indexOfLastTodo -  this.state.itemsPerPage;
  const activeData = this.state.hairstyles.slice(indexOfFirstTodo, indexOfLastTodo);
    let items = [];
  for (
    let number = 1;
    number <= Math.ceil(this.state.hairstyles.length / this.state.itemsPerPage);
    number++
  ) {
    items.push(
      <span
        style={this.state.activePage === number?{ cursor:"pointer",background: "white" ,padding:"10px",color:"black",margin:"5px",border:"1px solid black"}:{ cursor:"pointer",background:"rgb(38,43,64)" ,padding:"10px",color:"white",margin:"5px",border:"1px solid black"}}
        onClick={() => this.setState({activePage:number})}
        key={number}
      >
        {number}
      </span>
    );
  }

 const renderHairstyles = () => activeData.map(hairstyle => {
    //console.log(hairstyle.description.replace(/<[^>]+>/g, ''))
    return (
      <div style={{margin:"5px"}}>

        <CardUi imgSrc={hairstyle.picture ? this.displayImage(hairstyle.picture) : defaultImg}
          deleteButton="Remove Hairstyle"
          ListHairstyles={true}
          title={`${hairstyle.name}`}
          description={hairstyle.description.replace(/<[^>]+>/g, '')}
          deleteItem={() => this.removeHairstyle(hairstyle)}
          editHairstyle={()=>{
            history.push(`/components/editHairstyles/${hairstyle._id}`)
          }}
        // onButtonClick={()=>this.onButtonClick(hairstyle.id)} 
        ></CardUi>
        </div>
        )
      })
    
    if (this.state.isLoading) {      
      return (
        
        <ContentLoader
          speed={speed}
          width={columns * coverWidthWithPadding}
          height={rows * coverHeightWithPadding}
          primaryColor="#242b34"
          secondaryColor="#343d4c"
          {...this.props}
        >


          {covers.map((g, i) => {
            let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
            let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
            return (
              <rect
                key={i}
                x={vx}
                y={vy}
                rx="0"
                ry="0"
                width={coverWidth}
                height={coverHeight}
              />
            )
          })}
        </ContentLoader>
      )
    } else {
      return (
        <>
        <div className="container-fluid justify-content-center">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained"
              style={{ backgroundColor: "#262B40", color: "white" }}
              disableElevation startIcon={<Add />}
              size="large"
              onClick={this.handleShow}
            >
              Add Hairstyles
                </Button>
                <h2>
                {this.props.match.params.pkgName}
                 </h2> 
            <Button variant="contained"
              style={{ backgroundColor: "#262B40", color: "white" }}
              disableElevation startIcon={<Add />}
              size="large"
              onClick={() => history.push("/components/createhairstyles")}
            >
              Create Hairstyle
                </Button>

          </div>          
          <Modal show={this.state.show}
            hideModal={this.handleClose}
            title="Add HairStyles"
            addHairstyles={this.addHairstyles}
            removeFilteredHairstyles={this.removeFilteredHairstyles}
            items={this.state.filteredHairStyles}
            package={this.props.match.params.pkgId}
          ></Modal>
          <div className="row">
          <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",flexWrap:"wrap"}}>


            {this.state.hairstyles.length > 0 ? renderHairstyles() : <h3>No hair styles found Please add some!!</h3>}

            </div>
          </div>
          {console.log("ITEMS",items.length)}
        </div>
        <div className="paginate">
            <ReactPaginate
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    onPageChange={(e)=>{
                      this.fetchHairstyles(e.selected)
                    }}
                    pageRangeDisplayed={0}
                    marginPagesDisplayed={2}
                    pageCount={Math.ceil(this.state.hairCount / 7)}
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />      
        </div>   
        </>
      )
    }
  }
}
const mapStateToProps = (state) => {
  return ({ tokens: state.auth.tokens })
}


export default connect(mapStateToProps, { logout })(ListHairstyles);