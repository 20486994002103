import React from "react"
import CardUi from "../Cards/CardUI"
import defaultImg from "../../../assets/img/team/profile-picture-1.jpg"
import axios from "../../../api";
import Loader from "../helpers/Loader"
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import history from "../../../history";
import {connect} from "react-redux";
import {logout} from "../../../actions"
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
class ListTutorials extends React.Component {
    state = { tutorials: [], isLoading: true,searchedName: ""  }

    componentDidMount() {
        this.fetchTutorials()
    }

    searchName = async ($event) =>{
        console.log($event.target.value)
        this.setState({searchedName:$event.target.value})
        try{  
        this.setState({isLoading:true})
          let res = []
          if($event.target.value){
            res=(await axios.get(`/v1/tutorials?name=`+$event.target.value, {
                    headers: {
                      Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                    }
                  })).data.data
          }
          else{
            res=(await axios.get(`/v1/tutorials`,{
                    headers: {
                      Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                    }
                  })).data.data        
          }
          console.log("result",res)
          const tutorials = res.result;
        this.setState({tutorials})
        //   setHairStyleCount(res.count)
        //   setPageCount(Math.ceil(res.count / itemsPerPage));                                
          this.setState({isLoading:false})
        }catch(err){
            this.setState({isLoading:true})
          if(err.response&&(err.response.status==401||err.response.status==403)){
            this.props.logout(String(window.location.href));
        }
    
        console.log(err);
        }    
      }    
    fetchTutorials = async () => {
        try {
            const res = await axios.get(`/v1/tutorials`, {
                headers: {
                  Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                }
              })
            this.setState({ tutorials: res.data.data.result })
            console.log(this.state.tutorials);
            this.setState({ isLoading: false })
        } catch (err) {
            console.log(err);
            this.setState({ isLoading: true })
            if(err.response&&(err.response.status==401||err.response.status==403)){
                this.props.logout(String(window.location.href));
            }
        }
    }
    deleteTutorial = async (id) => {
        try{
        await axios.delete(`/v1/tutorials/${id}`, {
            headers: {
                Authorization:`Bearer ${this.props?.tokens?.access?.token}`
            }
        })
        this.setState({ tutorials: this.state.tutorials.filter((tutorial) => tutorial.id != id) })}catch(err){
            if(err.response&&(err.response.status==401||err.response.status==403)){
                this.props.logout(String(window.location.href));
            }
        }
    }

    displayImage = (img) => `data:image/png;base64,${img}`

    renderTutorials = () => this.state.tutorials.map(tutorial => {
        return (
            <div style={{ margin: '30px' }} className="col-md-3">
                <CardUi imgSrc={tutorial.picture ? this.displayImage(tutorial.picture) : defaultImg}
                    editButton="Edit Tutorial"
                    deleteButton="Delete Tutorial"
                    title={`${tutorial.name}`}
                    deleteItem={() => this.deleteTutorial(tutorial._id)}
                    editItem={()=>history.push(`/components/edittutorials/${tutorial._id}`)}
                ></CardUi>
            </div>
        )
    })
    onButtonClick = () => {
        console.log("button is clicked")
    }


    createTutorial = () => {
        history.push("/components/createtutorials")

    }
    render() {
            return (
                <div>
                    {this.state.isLoading&&<span className="loading">Loading...</span>}         
                <div className="container-fluid justify-content-center">                    
                    <div style={{margin: "10px"}} className="d-flex align-items-center">
                            <Form className="navbar-search">
                            <Form.Group id="topbarSearch">
                                <InputGroup className="input-group-merge search-bar">
                                <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                                <Form.Control onChange={this.searchName}  type="text" placeholder="Search" />
                                </InputGroup>
                            </Form.Group>
                            </Form>
                        </div>                                    
                    <Button variant="contained"
                        style={{ backgroundColor: "#262B40", color: "white" }}
                        disableElevation startIcon={<Add />}
                        size="large"
                        onClick={this.createTutorial}
                    >
                        Create Tutorial
                </Button>
                    <div className="row">
                        {this.state.tutorials.length > 0 ? this.renderTutorials() : <h3>No tutorials found Please add some!!</h3>}

                    </div>
                </div>
            </div>                
            )        
    }
}

const mapStateToProps = (state) => {
    return ({ tokens: state.auth.tokens })
}

export default connect(mapStateToProps,{logout})(ListTutorials);