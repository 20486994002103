import axios from "../../../api";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useRef } from "react";
import { Spinner } from "@themesberg/react-bootstrap";
import Swatch from "../../../assets/img/swatch.png";
import Hair from "../../../assets/img/Hairstyle.png";
import { SketchPicker } from "react-color";
import { useState } from "react";
import { connect } from "react-redux";
import { Button } from "@themesberg/react-bootstrap";
import Loader from "../../../components/Loader";
import "./addColors.css";
import { logout } from "../../../actions";
import imageToBase64 from "image-to-base64/browser";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const AddColors = (props) => {
  const HairImage = React.useRef(null);
  const HairImage2 = React.useRef(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [spacing, setSpacing] = React.useState(2);
  const [colorLoader, setColorLoader] = React.useState(false);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    fetchColors();
  }, []);
  const removeIndex = (i) => {
    setColors(colors.splice(i, 1));
  };
  const showSpinner = () => {
    if (colorLoader) {
      return (
        <Spinner animation="border" role="status" style={{ margin: "0 3rem" }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    } else {
      return "Loading...";
    }
  };

  const deleteColor = async (id) => {
    try {
      await axios.delete(`/v1/colors/${id}`, {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`,
        },
      });
      setAllColors(allColors.filter((color) => color.id != id));
    } catch (err) {
      if (
        err.response &&
        (err.response.status == 401 || err.response.status == 403)
      ) {
        props.logout(String(window.location.href));
      }
    }
  };
  const fetchColors = async () => {
    try {
      const res = await axios.get("/v1/colors", {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`,
        },
      });
      console.log(res.data.data.docs);
      setAllColors(res.data.data.docs);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (
        err.response &&
        (err.response.status == 401 || err.response.status == 403)
      ) {
        props.logout(String(window.location.href));
      }
    }
  };
  const [colors, setColors] = useState([]);
  const [colorBg, setColorBg] = useState("brown");
  const [rgb, setRgb] = useState(null);
  const [red, setRed] = useState(165);
  const [blue, setBlue] = useState(42);
  const [green, setGreen] = useState(42);
  const [strength, setStrength] = useState(50);
  // const [swatch, setSwatch] = useState(null)
  const [allColors, setAllColors] = useState([]);

  const [showPicker, setShowPicker] = useState(false);

  const displayHair = (r, g, b, s) => {
    // console.log("enter into display hair")

    window.Caman(`#${HairImage.current.id}`, function (err) {
      this.revert();
    });
    window.Caman(`#${HairImage.current.id}`, function (err) {
      this.colorize(r, g, b, s);
      this.render();
    });
  };
  const saveColors = async () => {
    setColorLoader(true);
    try {
      const arr = [];
      // await Promise.all(colors.map(async (color) => {

      const data = {
        red: red,
        blue: blue,
        green: green,
        strength: strength,
      };
      const res = await axios.post("/v1/colors", data, {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`,
        },
      });
      await fetchColors();
      setColorLoader(false);
      //setAllColors([res,...allColors])
      // setAllColors(arr.concat(allColors))
      setColors([]);
    } catch (err) {
      console.log(err);
      if (
        err.response &&
        (err.response.status == 401 || err.response.status == 403)
      ) {
        props.logout(String(window.location.href));
      }
    }
  };

  const handleChange = (color) => {
    console.log(color);
    setRed(color.rgb.r);
    setGreen(color.rgb.g);
    setBlue(color.rgb.b);
    setColorBg(color.hex);
    displayHair(color.rgb.r, color.rgb.g, color.rgb.b, strength);
  };
  const closeColorPicker = () => {
    const arr = [colorBg, ...colors];
    console.log(arr);
    setColors(arr);
    setShowPicker(false);
  };

  const editColors = async (item) => {
    setRed(item.red);
    setGreen(item.green);
    setBlue(item.blue);
    setStrength(item.strength);
    displayHair(item.red, item.green, item.blue, item.strength);
    setSelectedItem(item);
    //setColorBg(item);
  };
  const updateColors = async () => {
    setColorLoader(true);
    try {
      const arr = [];
      // await Promise.all(colors.map(async (color) => {
      const data = {};
      data.red = red;
      data.blue = blue;
      data.green = green;
      data.strength = strength;
      const res = await axios.patch("/v1/colors/" + selectedItem.id, data, {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`,
        },
      });
      console.log(res.data);
      setSelectedItem("");
      await fetchColors();
      setColorLoader(false);
      //setAllColors([res,...allColors])
      //setColors([])
    } catch (err) {
      console.log(err);
      if (
        err.response &&
        (err.response.status == 401 || err.response.status == 403)
      ) {
        props.logout(String(window.location.href));
      }
    }
  };

  const deleteColors = async (item) => {
    setColorLoader(true);
    try {
      const arr = [];
      // await Promise.all(colors.map(async (color) => {

      const res = await axios.delete("/v1/colors/" + item.id, {
        headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`,
        },
      });
      console.log(res);
      await fetchColors();
      setColorLoader(false);
      //setAllColors([res,...allColors])
      setColors([]);
    } catch (err) {
      console.log(err);
      if (
        err.response &&
        (err.response.status == 401 || err.response.status == 403)
      ) {
        props.logout(String(window.location.href));
      }
    }
  };

  return (
    <div>
      {colorLoader && <span className="loading">Loading...</span>}

      {/* <h1>Add Colors</h1>
            <div className="color-selector-div">

                <input className="color-input" value={colors.join(",")} onInput={e => setColors(e.target.value.split(","))} placeholder="Enter Color Codes seprated with commas" />


            </div> */}
      <h1>Add Color</h1>
      <div className="color-selector-div -div d-flex justify-content-center align-items-center flex-column  d-md-flex justify-md-content-center align-md-items-center  flex-md-row">
        <label for="red">Red</label>
        <input
          style={{ marginLeft: "8px", marginRight: "8px" }}
          type="number"
          id="red"
          min="0"
          max="255"
          value={red}
          onInput={(e) => {
            setRed(e.target.value);
            displayHair(e.target.value, green, blue, strength);
          }}
        />
        <label for="green">Green</label>
        <input
          style={{ marginLeft: "8px", marginRight: "8px" }}
          type="number"
          id="green"
          min="0"
          max="255"
          value={green}
          onInput={(e) => {
            setGreen(e.target.value);
            displayHair(red, e.target.value, blue, strength);
          }}
        />
        <label for="blue">Blue</label>
        <input
          style={{ marginLeft: "8px", marginRight: "8px" }}
          type="number"
          id="blue"
          min="0"
          max="255"
          value={blue}
          onInput={(e) => {
            setBlue(e.target.value);
            displayHair(red, green, e.target.value, strength);
          }}
        />
        <label for="strength">Strength</label>
        <input
          style={{ marginLeft: "8px", marginRight: "8px" }}
          type="number"
          id="strength"
          min="0"
          value={strength}
          onInput={(e) => {
            setStrength(e.target.value);
            displayHair(red, green, blue, e.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/*<div className="swatch">
                    <img className="image-show" style={{margin:"20px"}} id="sw" src={Swatch}/>
                     <canvas id="sw-1"></canvas>
                    {swatch?
                    <img src={swatch}/>
                    :null} 
                </div>*/}
        <div className="Grayhair">
          <img
            ref={HairImage}
            className="gray-show"
            style={{ margin: "10px auto" }}
            id="hair"
            src={Hair}
          />
          {/* <canvas id="sw-1"></canvas>
                    {swatch?
                    <img src={swatch}/>
                    :null} */}
        </div>
      </div>
      <div className="colorpicker-div">
        {showPicker ? (
          <div>
            <SketchPicker
              color={colorBg}
              onChange={handleChange}

              // onChange={(color) => {
              //     setRed(color.rgb.r);
              //     setGreen(color.rgb.g);
              //     setBlue(color.rgb.b);
              //     // setColorBg(color.hex);console.log(color);setRgb(color.rgb)
              // }}
            />

            {/* <Button style={{ marginRight: "3px" }} onClick={handleChangeComplete}>Select Color</Button> */}
            <Button style={{ margin: "5px" }} onClick={closeColorPicker}>
              Close color picker
            </Button>
          </div>
        ) : (
          <div>
            <Button onClick={() => setShowPicker(true)}>
              Open Color Picker
            </Button>
          </div>
        )}
      </div>

      <div className="savecolor-div">
        {selectedItem == "" && <Button onClick={saveColors}>Add Colors</Button>}
        {selectedItem != "" && (
          <Button onClick={updateColors}>Update Colors</Button>
        )}
      </div>

      <div>
        <h3>Saved Colors</h3>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={spacing}>
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              ) : (
                allColors.map((color) => (
                  <Grid key={color.id} item>
                    <div className="showColors-div">
                      <img
                        onClick={() => editColors(color)}
                        className="image-show"
                        src={color.picture}
                      />
                      <div style={{ marginBottom: "4px", marginTop: "4px" }}>
                        <Button onClick={() => deleteColors(color)}>
                          delete Colors
                        </Button>
                      </div>
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { tokens: state.auth.tokens };
};
export default connect(mapStateToProps, { logout })(AddColors);
