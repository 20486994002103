import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faCartArrowDown,
  faChartPie,
  faChevronDown,
  faClipboard,
  faCommentDots,
  faFileAlt,
  faPlus,
  faRocket,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";
import { connect } from "react-redux";
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { setCurrentLoggedIn, logout } from "../actions";
import axios from "../api";

const Settings = (props) => {
  const [phone, setPhone] = useState(props.currentUser.phone);
  const [gender, setGender] = useState(props.currentUser.gender);
  const [firstName, setFirstName] = useState(props.currentUser.first_name);
  const [lastName, setLastName] = useState(props.currentUser.last_name);
  const [profile, setProfile] = useState(null);
  const [email, setEmail] = useState(props.currentUser.email);
  const updateUser = async (e) => {
    e.preventDefault();
    if (firstName && lastName && gender && profile && phone && email) {
      if (profile.size <= 800000) {
        try {
          var formData = new FormData();
          formData.append("first_name", firstName);
          formData.append("last_name", lastName);
          formData.append("gender", gender);
          formData.append("profile", profile);
          formData.append("phone", phone);
          formData.append("email", email);
          const res = await axios.patch(
            `/v1/users/${props.currentUser.id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${props?.tokens?.access?.token}`,
              },
            }
          );
          props.setCurrentLoggedIn({ user: res.data, tokens: props.tokens });
          alert("Updated!!");
        } catch (err) {
          if (
            err.response &&
            (err.response.status == 401 || err.response.status == 403)
          ) {
            props.logout(String(window.location.href));
          }
        }
      } else {
        alert("File size should not be greater than 800kb");
      }
    } else {
      alert("all information is necessary");
    }
  };
  const OnPhotoSelect = (event) => {
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/gif" ||
      event.target.files[0].type === "image/png"
    ) {
      setProfile(event.target.files[0]);

      console.log()
    } else {
      alert("uploaded image should be of gif,jpg or png format");
    }
    // if(event.target.files[0]){
    //   var formData = new FormData();
    // formData.append("profile",event.target.files[0])
    // }
  };
  const displayImage = (img) => `data:image/png;base64,${img}`;
  console.log(props.currentUser);
  return (
    <>
      <Row>
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">General information</h5>
              <Form onSubmit={updateUser}>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="firstName">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        placeholder="Enter your first name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="lastName">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        placeholder="Also your last name"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  {/* <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Birthday</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={setBirthday}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={birthday ? moment(birthday).format("MM/DD/YYYY") : ""}
                        placeholder="mm/dd/yyyy"
                        onFocus={openCalendar}
                        onChange={() => { }} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col> */}
                  <Col md={6} className="mb-3">
                    <Form.Group id="gender">
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        onChange={(e) => setGender(e.target.value)}
                        defaultValue={gender}
                      >
                        <option value="0">Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="emal">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="name@company.com"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="phone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        placeholder="+12-345 678 910"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-3">
                  <Button variant="primary" type="submit">
                    Save All
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget
                role={props.currentUser.role}
                image={
                  props.currentUser.profile
                    ? displayImage(props.currentUser.profile)
                    : Profile3
                }
                title={firstName}
              />
            </Col>
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select profile photo"
                photo={profile ? URL.createObjectURL(profile) : null}
                OnPhotoSelect={OnPhotoSelect}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  return { currentUser: state.auth.user, tokens: state.auth.tokens };
};
export default connect(mapStateToProps, {
  setCurrentLoggedIn: setCurrentLoggedIn,
  logout: logout,
})(Settings);
