import React, { Component } from "react";
import { ChoosePhotoWidget } from "../../../components/Widgets";
import { Col, Row, Card, Form, Button, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import Loading from '../../../components/Loader'
import { Link } from 'react-router-dom';
import axios from "../../../api";
import history from "../../../history";
import { connect } from "react-redux";
import { Multiselect } from 'multiselect-react-dropdown';
import Loader from "../helpers/Loader";
import {logout} from "../../../actions"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Grid from "../helpers/Grid"
import "./hairstyle.scss"
const reducer=(state,action)=>{
  switch(action.type){
    case "EDITFORMDATALOADFALSE":
      return {
        ...state,
        editFormDataLoad:false
      }
    case "EDITFORMDATALOAD":
      return {
        ...state,
        editFormDataLoad:true
      }
    case "SETEDITIMAGEBOX":
      return {
        ...state,
        showEditImageBox:!state.showEditImageBox
      }
    case "ADDNAME":
      return {
        ...state,
        name:action.payload
      }
      case "ADDNUMBER":
        return {
          ...state,
          number:action.payload
        }  
    case "ADDDESCRIPTION":
      return {
        ...state,
        description:action.payload
      }
    case "ADDGREYSCALE":
      return{
        ...state,
        greyScale:action.payload
      }
    case "ADDPICTURE":
      return{
        ...state,
        picture:action.payload
      }
    case "CATEGORYCHANGE":
      return{
        ...state,
        category:action.payload
      }
    case "PACKAGECHANGE":
      return{
        ...state,
        packages:action.payload
      }
    case "SETHAIRSTYLELOADFALSE":
      return{
        ...state,
        loadingHairstyleData:false
      }
    case "SETHAIRSTYLELOADTRUE":
      return{
        ...state,
        loadingHairstyleData:true
      }
    case "SETPACKAGELOADFALSE":
      return{
        ...state,
        loadingPackages:false
      }
    case "SETPACKAGELOADTRUE":
      return{
        ...state,
        loadingPackages:true
      }
    case "ADDDATA":
      console.log(action.payload,"ACTIONS.PAYLOAD")
      return{
        ...state,
        ...action.payload

      }
    case "ADDPACKAGES":
      return {
        ...state,
        allPackages: action.payload, 
        multiselectDropdownOptionLoader: false 
      }
    default:
      return state
  }
}

function EditHairStyles(props) {
  const [state,dispatch] = React.useReducer(reducer,{
    id: null,
    allPackages: [],
    filteredPackages: [],
    packages: [],
    availableColors: [],
    name: "",
    number:null,
    description: "",
    category: props.category?props.category:[],
    picture: null,
    greyScale:null,
    hairstyleEditLoader: false,
    multiselectDropdownOptionLoader: true,
    defaultPic: null,
    loadingPackages: false,
    loadingHairstyleData:false,
    showEditImageBox:false,
    editFormDataLoad:false
  })


  React.useEffect(()=>{
    dispatch({type:"SETHAIRSTYLELOADTRUE"})
    dispatch({type:"SETPACKAGELOADTRUE"})
    axios.get(`/v1/packages`,{
      headers: {
          Authorization: `Bearer ${props?.tokens?.access?.token}`
      }
    })
    .then(result=>{
      dispatch({type:"ADDPACKAGES",payload:result.data.data.result})
      dispatch({type:"SETPACKAGELOADFALSE"})
      axios.get(`/v1/hairstyles/${props.match.params.id}`,{
        headers: {
            Authorization: `Bearer ${props?.tokens?.access?.token}`
        }
      })
      .then(res=>{
        const { _id, name, packages, description, category, picture, greyScale,number } = res.data.data
        const pkgs = result.data.data.result.filter(pkg => packages.indexOf(pkg._id) != -1) 
        const categoryArr =category
        dispatch({type:"ADDDATA",payload: { id: _id,number:number, name: name, packages: pkgs, description: description, category: categoryArr}})  
        dispatch({type:"SETHAIRSTYLELOADFALSE"})
        dispatch({type:"SETPACKAGELOADFALSE"})  
        console.log(pkgs,"packages")
      })
      .catch(err=>{
        console.log(err)
        dispatch({type:"SETHAIRSTYLELOADFALSE"})
        dispatch({type:"SETPACKAGELOADFALSE"})
        if(err.response&&(err.response.status==401||err.response.status==403)){
          props.logout(String(window.location.href));
      }
      })
    })
    .catch(err=>{
      console.log(err)
      dispatch({type:"SETPACKAGELOADFALSE"})
      if(err.response&&(err.response.status==401||err.response.status==403)){
        props.logout(String(window.location.href));
    }
    })
    console.log(`/v1/hairstyles/${props.match.params.id}`,'LINK')
    



  },[])
  

  const displayImage = (img) => `data:image/png;base64,${img}`
  const displayGreyScaleImage = (img) => `data:image/png;base64,${img}`
  
  const renderPackageOptions = () => state.packages.map((pkg, i) => {
    return (
      <option value={JSON.stringify(pkg)}>{pkg.description}</option>
    )
  })

  const renderMultiSelectOptions = () => state.packages.map((pkg, i) => {
    const options = []
    options.push(pkg.description)

    console.log("options are " + options);
    return options;
  })

  const checkCategory = (categorys, categoryArr) => categorys ? categorys.some(category => {
    return categoryArr.map(el => el.id).indexOf(category) != -1
  }) : false

  const onSelectCategory = (selectedItem) => {
    const arr = [...selectedItem]
    dispatch({type:"CATEGORYCHANGE",payload:arr})
    // const filteredPackages=state.allPackages.filter(pkg=>checkCategory(pkg.category,arr))
    // setState({filteredPackages})
  }

  const onRemoveCategory = (selectedItem) => {
    const arr = [...selectedItem]
    dispatch({type:"CATEGORYCHANGE",payload:arr})
    // const filteredPackages=state.allPackages.filter(pkg=>checkCategory(pkg.category,arr))
    // setState(.allPackages})
  }
  const editHairstyle = async () => {
    dispatch({type:"EDITFORMDATALOAD"})
    var formData = new FormData();
    console.log(state.packages);
    formData.append("name", state.name);
    formData.append("number", state.number);
    state.category.forEach(category => {
      formData.append("category", category.id);
    })
    formData.append("description", state.description);
    if (state.picture) {
      formData.append("picture", state.picture);
    }
    if (state.greyScale) {
      formData.append("greyScale", state.greyScale);
    }
    state.packages.forEach(pkg => {
      formData.append("packages", pkg._id);
    })
    // formData.append("availableColors", "original");
    // state.availableColors.forEach(color => {
    //   formData.append("availableColors", color);
    // })
    try {
      const res = await axios.patch(`/v1/hairstyles/${state.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${props?.tokens?.access?.token}`
        }
      })
      dispatch({type:"EDITFORMDATALOADFALSE"})

    history.push(`/components/listallhairstyles`)
      console.log(res.data);
    }
    catch (err) {
      dispatch({type:"EDITFORMDATALOADFALSE"})
      console.log(err)
      alert("Server down")
      if(err.response&&(err.response.status==401||err.response.status==403)){
        props.logout(String(window.location.href));
    }

    }

  }



  const showSpinner = () => {
    if (state.hairstyleEditLoader) {
      return (
        <Spinner animation="border" role="status" style={{ margin: "0 3rem" }}>
          <span className="sr-only">Loading...</span>
        </Spinner>
      )
    } else {
      return "Loading..."
    }
  }

  const onSave = async (e) => {
    e.preventDefault()
    if(!state.number || !state.name || !state.description || state.category.length<=0 || state.packages.length <=0){
      if(!state.name){
        alert("please enter name")
      }else if(!state.description){
        alert("please enter description")
      }else if(!state.number){
        alert("please enter number")
      }else if(state.category.length<=0){
        alert("please select category")
      }else if(state.packages.length <=0){
        alert("please select package")
      }
    }else{
      await editHairstyle();
    }
  }
  const OnPhotoSelect = (event) => {
    console.log(event.target.files[0])
    dispatch({type:"ADDPICTURE",payload:event.target.files[0]})

  }

  const OnGreyScalePhotoSelect = (event) => {
    console.log(event.target.files[0])
    dispatch({type:"ADDGREYSCALE",payload:window.URL.createObjectURL(event.target.files[0])})
  }
  
  const onSelect = (selectedItem) => {
    console.log(selectedItem,selectedItem);
    dispatch({type:"PACKAGECHANGE",payload:selectedItem})
  }

  const onRemove = (selectedItem) => {
    dispatch({type:"PACKAGECHANGE",payload:selectedItem})
  }



    return (
      state.loadingHairstyleData ? (<Loader></Loader>) : state.editFormDataLoad? <div className="d-flex justify-content-center align-items-center"><Loading/></div>:
        <>
        {state.hairstyleEditLoader&&<span className="loading">Loading...</span>}                                                
          <Row>
            <Col xs={12} >
              <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                  <h5 className="mb-4">Edit Hairstyle</h5>
                  <Form onSubmit={onSave}>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="hairstyleName">
                          <Form.Label>Hairstyle Name</Form.Label>
                          <Form.Control onChange={e => dispatch({ type:"ADDNAME",payload: e.target.value })} value={state.name} required type="text" placeholder="Enter the hairstyle name" />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group id="category">
                          <Form.Label>Category</Form.Label>
                          <Multiselect
                            singleSelect={true}
                            options={props.category}
                            placeholder="Select Category"
                            selectedValues={state.category}
                            onSelect={onSelectCategory} // Function will trigger on select event
                            onRemove={onRemoveCategory} // Function will trigger on remove event
                            displayValue="categoryName" // Property name to display in the dropdown options
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="packages">
                          <Form.Label>Packages</Form.Label>
                          <Multiselect

                            options={state.allPackages}
                            placeholder="Select Package"
                            loading={state.multiselectDropdownOptionLoader}
                            // Options to display in the dropdown
                            selectedValues={state.packages} // Preselected value to persist in dropdown
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            displayValue="description" // Property name to display in the dropdown options
                          />
                          {/* <Form.Select  defaultValue={JSON.stringify(state.packages[0])} onInput={(e)=>setState({package:JSON.parse(e.target.value)})}>
                  {renderPackageOptions()}
                </Form.Select>
                */}
                          <Link to="/components/createPackages" className="btn btn-outline-success mt-3">Edit New Package</Link>
                        </Form.Group>
                      </Col>
                      {/* <Col md={6} className="mb-3">
                        <Form.Group id="availableColors">
                          <Form.Label>Colors</Form.Label>
                          <Form.Control onInput={(e) => setState({ availableColors: e.target.value.split(" ") })} required type="text" value={state.availableColors} placeholder="blue pink" />
                        </Form.Group>
                      </Col> */}
                      <Col md={6} className="mb-3">
                        <Form.Group id="hairstyleName">
                          <Form.Label>Hairstyle Number</Form.Label>
                          <Form.Control onChange={e => dispatch({ type:"ADDNUMBER",payload: e.target.value })} value={state.number} required type="text" placeholder="Enter the hairstyle number" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={9} className="mb-3">
                        <Form.Group id="description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control onChange={(e) => dispatch({ type: "ADDDESCRIPTION",payload:e.target.value })} value={state.description} required type="text" placeholder="Enter hairstyle description" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button onClick={()=>{
                      dispatch({type:"SETEDITIMAGEBOX"})
                    }} className="btn btn-outline-success mt-3">Update Images</Button>

                    {state.showEditImageBox &&
                    <>
                    <Row>
                      <Col xs={12}>
                        <ChoosePhotoWidget
                          title="Select hairstyle picture"
                          photo={state.picture ? URL.createObjectURL(state.picture) : ""}
                          OnPhotoSelect={OnPhotoSelect}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <ChoosePhotoWidget
                          title="Select grey scale picture"
                          photo={state.greyScale ? state.greyScale:""}
                          OnPhotoSelect={OnGreyScalePhotoSelect}
                        />
                      </Col>
                    </Row>  </> }                 
                    <div className="mt-3">
                    <Button variant="primary" type="submit" >Edit Hairstyle</Button>
                      {/* <Button variant="primary" type="submit" >{state.hairstyleEditLoader ? showSpinner() : "Edit Hairstyle"}</Button> */}
                    </div>

                  </Form>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </>
    );
  
};
const mapStateToProps = (state) => {
  return ({ tokens: state.auth.tokens, category: state.current.category })
}
export default connect(mapStateToProps,{logout})(EditHairStyles)