import React from "react";
import { Field,reduxForm } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt,faPhoneAlt, faMale, faPersonBooth } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import "./Signin.css";

import LoadImg from "../../components/SmallLoader"
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { connect } from "react-redux";
import { register } from "../../actions";
import axios from "../../api"

const renderInput=({input,icon,label,placeholder,meta})=>{
  console.log(input);
  const isError =meta.touched&&meta.error
  return(
  <Form.Group id={input.name} className="mb-4">
    <Form.Label> {label}</Form.Label>
    <InputGroup>
      <InputGroup.Text>
        <FontAwesomeIcon icon={icon} />
      </InputGroup.Text>
      <Form.Control {...input} required type={input.name==="confirmPassword"||input.name==="password"?"password" : "text"} placeholder={placeholder}/>
    </InputGroup>
    <div style={{color:'red'}}>{isError?meta.error: null}</div>
  </Form.Group>
  )
}
const renderOptns=(options)=>options.map(optn=>{
  return  <option value={optn}>{optn}</option>
})

const renderInputOptions=({input,icon,options,label,placeholder,meta})=>{
  console.log(input,"INPUT",placeholder,"PLACEHOLDER",label,"LABEL");
  const isError =meta.touched&&meta.error
  return(
  <Form.Group placeholder={placeholder} id={input.name} className="mb-4">
    <Form.Label> {label}</Form.Label>
    <Form.Select  {...input} >
    <option value="" disabled selected>{label}</option>
                 {renderOptns(options.split(","))}
                </Form.Select>
    {/* <InputGroup>
      <InputGroup.Text>
        <FontAwesomeIcon icon={icon} />
      </InputGroup.Text>
      <Form.Control {...input} required type={input.name==="email"||input.name==="password"?input.name : "text"} placeholder={placeholder}/>
    </InputGroup> */}
    <div style={{color:'red'}}>{isError?meta.error: null}</div>
  </Form.Group>
  )
}

const Signup = (props) => {
  const  submit=(formProps)=>{
    const {email,password,role,phone,first_name,gender,last_name}=formProps
    props.register({email,password,role,gender,phone,first_name,last_name})
}


  return (
    <>
    <div className="d-flex justify-content-center">
    <Row>
      <div className="heading1">
        Hairstyle
    </div>

<div style={{margin:'100px 0' , border:'3px black solid', padding:'60px' , borderRadius:'20px'}}>
<div className="heading2"> 
  Signin
  </div>
          <Form onSubmit={props.handleSubmit(submit)} >               
            <Field name="email" label="Your Email" placeholder="john@company.com" icon={faEnvelope} component={renderInput}/>
            <Field name="password" label="Your Password" placeholder="Password" icon={faUnlockAlt} component={renderInput}/>
            <Field name="confirmPassword" label="Confirm Password" placeholder="Confirm Password" icon={faUnlockAlt} component={renderInput}/>
            <Field name="gender" label="Your Gender" placeholder="Gender" icon={faMale} options="Male,Female" component={renderInputOptions}/>
            <Field name="first_name" label="First Name" placeholder="John" icon={faPersonBooth} component={renderInput}/>
            <Field name="last_name" label="Last Name" placeholder="Doe" icon={faPersonBooth} component={renderInput}/>
            <Field name="phone" label="Phone" placeholder="(+91**********)" icon={faPhoneAlt} component={renderInput}/>
            <Field name="role" label="Role" placeholder="user" icon={faPersonBooth} options="user,admin" component={renderInputOptions}/>
          <Form.Group>
                
               </Form.Group>
               <div className="text-center" style={{margin:'10px 0'}} >
                 <div style={{width:'200px',display:'inline-block'}} >
                {props.loading? <div className="d-flex justify-content-center align-items-center"><LoadImg/></div>: <Button variant="primary" type="submit" className="w-100">
                 Signup
               </Button>}
                 </div>
              </div>
              <div className="text-center" style={{margin:'10px 0'}} >
                 {/* <div style={{width:'200px',display:'inline-block'}} >
                   <div style={{display:'flex',justifyContent:'space-between'}}>
                   <a href="http://localhost:8000/v1/auth/facebook" variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                  <FontAwesomeIcon className="icon" icon={faFacebookF} />
                </a>
                <a href="http://localhost:8000/v1/auth/google" variant="outline-light" className="btn-icon-only btn-pill text-google me-2">
                  <FontAwesomeIcon className="icon" icon={faGoogle} />
                </a>
                </div>
                 </div> */}
                 <div className="d-flex justify-content-center align-items-center mt-4">
                   <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                    </span>
                 </div>
              </div>

             </Form>
      </div> 
    </Row>
    
     
    </div>
    </>

)


//   return (
//     <main>
//       <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
//         <Container>
//           <p className="text-center">
//             <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
//               <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
//             </Card.Link>
//           </p>
//           <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
//             <Col xs={12} className="d-flex align-items-center justify-content-center">
//               <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
//                 <div className="text-center text-md-center mb-4 mt-md-0">
//                   <h3 className="mb-0">Create an account</h3>
//                 </div>
//                 <Form onSubmit={props.handleSubmit(submit)} className="mt-4">
                  // <Field name="email" label="Your Email" placeholder="example@company.com" icon={faEnvelope} component={renderInput}/>
                  // <Field name="password" label="Your Password" placeholder="Password" icon={faUnlockAlt} component={renderInput}/>
                  // <Field name="confirmPassword" label="Confirm Password" placeholder="Confirm Password" icon={faUnlockAlt} component={renderInput}/>
                  // <Field name="gender" label="Your Gender" placeholder="Gender" icon={faMale} options="male,female" component={renderInputOptions}/>
                  // <Field name="first_name" label="First Name" placeholder="example" icon={faPersonBooth} component={renderInput}/>
                  // <Field name="last_name" label="Last Name" placeholder="example" icon={faPersonBooth} component={renderInput}/>
                  // <Field name="phone" label="Phone" placeholder="2345667" icon={faPhoneAlt} component={renderInput}/>
                  // <Field name="role" label="Role" placeholder="user" icon={faPersonBooth} options="user,admin" component={renderInputOptions}/>
                  
//                   <FormCheck type="checkbox" className="d-flex mb-4">
//                     <FormCheck.Input required id="terms" className="me-2" />
//                     <FormCheck.Label htmlFor="terms">
//                       I agree to the <Card.Link>terms and conditions</Card.Link>
//                     </FormCheck.Label>
//                   </FormCheck>

//                   <Button variant="primary" type="submit" className="w-100">
//                     Sign up
//                   </Button>
//                 </Form>

//                 <div className="mt-3 mb-4 text-center">
//                   <span className="fw-normal">or</span>
//                 </div>
//                 <div className="d-flex justify-content-center my-4">
//                   <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
//                     <FontAwesomeIcon icon={faFacebookF} />
//                   </Button>
//                   <a href="http://localhost:8000/v1/auth/google" variant="outline-light" className="btn-icon-only btn-pill text-google me-2">
//                     <FontAwesomeIcon icon={faGoogle} />
//                   </a>
//                   <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
//                     <FontAwesomeIcon icon={faGithub} />
//                   </Button>
//                 </div>
//                 <div className="d-flex justify-content-center align-items-center mt-4">
                  // <span className="fw-normal">
                  //   Already have an account?
                  //   <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                  //     {` Login here `}
                  //   </Card.Link>
//                   </span>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </main> 
//   );
};

const validate=formValues=>{
  const errors={};

  if(!formValues.email){
      errors.email="Must have an email"
  }else if(!formValues.email.includes("@")){
      errors.email="Please enter valid email"
  }
  if(!formValues.password){
      errors.password="Must have a password"
  }else if(formValues.password.length < 6){
      errors.password="Must contains atleast 6 characters"
  }
  if(formValues.confirmPassword!==formValues.password){
    errors.confirmPassword="Passwords doesn't match"
}
  if(!formValues.first_name){
      errors.first_name="Must have a firstName"
  }
  if(!formValues.last_name){
      errors.last_name="Must have a lastName"
  }
  if(!formValues.role){
      errors.role="Must have a role"
  }
  if(!formValues.gender){
    errors.gender="Must have a gender"
}
  if(!formValues.phone){
      errors.phone="Please specify phone number"
  }
  else if(formValues.phone.length < 10){
      errors.phone="Please enter valid phone number"
  }
  console.log(errors);
  return errors
}
const mapStateToProps=(state)=>{
  return ({loading:state.auth.loading})
}
const SignupForm= reduxForm({
  form:'signupForm',
  validate
})(Signup)
export default connect(mapStateToProps,{register:register})(SignupForm)