import React from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  Form,
} from "@themesberg/react-bootstrap";
import axios from "../../../api";
import { connect } from "react-redux";
import TableLoader from "./TableLoader";
import { logout } from "../../../actions";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faEnvelopeOpen,
  faAngleRight,
  faAngleLeft,
  faSearch,
  faSignOutAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@material-ui/core";
import { jsonToCSV } from "react-papaparse";
function Customers(props) {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userCount, setUserCount] = React.useState(0);
  const [searchUseKeyword, setSearchUseKeyword] = React.useState("");
  const [searchUseEmailKeyword, setSearchUseEmailKeyword] = React.useState("");
  const [currentPageCount, setCurrentPageCount] = React.useState(1);
  const [showActive, setShowActive] = React.useState(false);
  const [showInactive, setShowInactive] = React.useState(false);
  const ShowActiveRef = React.useRef(showActive);
  const ShowInActiveRef = React.useRef(showInactive);
  React.useEffect(() => {
    fetchAllUsers();
  }, []);

  React.useEffect(() => {
    if (!searchUseKeyword) {
      fetchAllUsers();
    }
  }, [searchUseKeyword]);
  React.useEffect(() => {
    if (!searchUseEmailKeyword) {
      fetchAllUsers();
    }
  }, [searchUseEmailKeyword]);

  async function fetchAllUsers(pageNumber) {
    console.log(showActive);
    console.log(showInactive);
    console.log(pageNumber, "pageNumber");
    const tokens = props.tokens
      ? props.tokens
      : JSON.parse(localStorage.getItem("auth")).tokens;

    try {
      setIsLoading(true);
      const response = await axios.get(
        `/v1/users?role=user&page=${
          pageNumber ? pageNumber : 1
        }&sortBy=createdAt:desc${
          searchUseKeyword
            ? "&first_name=" + searchUseKeyword.toLowerCase()
            : ""
        }${searchUseEmailKeyword ? "&email=" + searchUseEmailKeyword : ""}${
          ShowActiveRef.current ? "&status=active" : ""
        }${ShowInActiveRef.current ? "&status=inactive" : ""}`,
        {
          headers: {
            Authorization: `Bearer ${tokens.access.token}`,
          },
        }
      );

      setUsers(response.data.results);
      setUserCount(response.data.totalResults);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (
        err.response &&
        (err.response.status == 401 || err.response.status == 403)
      ) {
        props.logout(String(window.location.href));
      }
      console.log(err);
    }
  }
  async function downloadCSV() {
    const tokens = props.tokens
      ? props.tokens
      : JSON.parse(localStorage.getItem("auth")).tokens;

    try {
      setIsLoading(true);
      const response = await axios.get(`/v1/users/users/csv`, {
        headers: {
          Authorization: `Bearer ${tokens.access.token}`,
        },
      });

      console.log(response);
      downloadUserFile(response.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (
        err.response &&
        (err.response.status == 401 || err.response.status == 403)
      ) {
        props.logout(String(window.location.href));
      }
      console.log(err);
    }
  }

  function downloadUserFile(data) {
    var csv = jsonToCSV(data);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "download.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "download.csv");
    tempLink.click();
  }

  const displayImage = (img) => `data:image/png;base64,${img}`;
  function tableRow(user) {
    const { profile, first_name, last_name, gender, email, active, provider } =
      user;

    return (
      <tr>
        <td className="fw-bold border-0">{`${first_name} ${last_name}`}</td>
        <td className="border-0">{email ? email : "--"}</td>
        <td className="border-0">{gender ? gender : "--"}</td>
        <td className="border-0">{provider}</td>
        <td className="border-0">{active.toString()}</td>
        <td className="border-0">
          <Button
            onClick={async () => {
              if (
                window.confirm(
                  "Deleting will permanently remove this user and data related to this user, do you still want to continue?"
                )
              ) {
                const tokens = props.tokens
                  ? props.tokens
                  : JSON.parse(localStorage.getItem("auth")).tokens;
                let response = await axios.delete(
                  `/v1/users/permanent/${user.id}`,
                  {
                    headers: {
                      Authorization: `Bearer ${tokens.access.token}`,
                    },
                  }
                );
                console.log(response);
                fetchAllUsers();
              }
            }}
          >
            Delete
          </Button>
          {active && (
            <Button
              onClick={async () => {
                const tokens = props.tokens
                  ? props.tokens
                  : JSON.parse(localStorage.getItem("auth")).tokens;
                let response = await axios.delete(`/v1/users/${user.id}`, {
                  headers: {
                    Authorization: `Bearer ${tokens.access.token}`,
                  },
                });
                console.log(response);
                fetchAllUsers();
              }}
            >
              Disable
            </Button>
          )}
        </td>
      </tr>
    );
  }
  const renderUserList = () => {
    if (users.length > 0) {
      return users.map((user) => tableRow(user));
    }
  };

  if (users.length > 0) {
    return (
      <div>
        <h4>Our Customers</h4>

        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Search by first name"
              value={searchUseKeyword}
              onChange={(e) => {
                setSearchUseKeyword(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                fetchAllUsers(currentPageCount);
              }}
            >
              {" "}
              Search
            </Button>
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <TextField
              placeholder="Search by Email"
              value={searchUseEmailKeyword}
              onChange={(e) => {
                setSearchUseEmailKeyword(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                fetchAllUsers(currentPageCount);
              }}
            >
              {" "}
              Search
            </Button>
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <Button
              onClick={() => {
                downloadCSV();
              }}
            >
              {" "}
              Download Users CSV
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Check
              type={"checkbox"}
              id={`showActive`}
              label={`Show active`}
              checked={showActive}
              onChange={(e) => {
                console.log(e.target.checked);
                setShowActive(e.target.checked);
                ShowActiveRef.current = e.target.checked;
                setTimeout(() => {
                  fetchAllUsers();
                }, 2000);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Check
              type={"checkbox"}
              id={`showInactive`}
              label={`Show inactive`}
              checked={showInactive}
              onClick={(e) => {
                console.log(e.target.checked);
                setShowInactive(e.target.checked);
                ShowInActiveRef.current = e.target.checked;
                setTimeout(() => {
                  fetchAllUsers();
                }, 2000);
              }}
            />
          </div>
        </div>
        <div>
          <Card border="light" className="shadow-sm">
            <Card.Body className="pb-0">
              <Table
                responsive
                className="table-centered table-nowrap rounded mb-0"
              >
                <thead className="thead-light">
                  <tr>
                    {/* <th className="border-0">Profile</th> */}
                    <th className="border-0">Name</th>
                    <th className="border-0">Email</th>
                    <th className="border-0">Gender</th>
                    <th className="border-0">Provider</th>
                    <th className="border-0">Active</th>
                    <th className="border-0">Options</th>
                  </tr>
                </thead>
                <tbody className="Customer-tbody">{renderUserList()}</tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
        <ReactPaginate
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          onPageChange={(e) => {
            setCurrentPageCount(parseInt(e.selected) + 1);
            fetchAllUsers(parseInt(e.selected) + 1);
          }}
          pageRangeDisplayed={0}
          marginPagesDisplayed={2}
          pageCount={Math.ceil(userCount / 10)}
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    );
  } else {
    return isLoading ? (
      <div>
        <h4> Our Customers</h4>
        <TableLoader> </TableLoader>
      </div>
    ) : (
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Search by first name"
              value={searchUseKeyword}
              onChange={(e) => {
                setSearchUseKeyword(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                fetchAllUsers(currentPageCount);
              }}
            >
              {" "}
              Search
            </Button>
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <TextField
              placeholder="Search by Email"
              value={searchUseEmailKeyword}
              onChange={(e) => {
                setSearchUseEmailKeyword(e.target.value);
              }}
            />
            <Button
              onClick={() => {
                fetchAllUsers(currentPageCount);
              }}
            >
              {" "}
              Search
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Check
              type={"checkbox"}
              id={`showActive`}
              label={`Show active`}
              checked={showActive}
              onChange={(e) => {
                console.log(e.target.checked);
                setShowActive(e.target.checked);
                ShowActiveRef.current = e.target.checked;
                setTimeout(() => {
                  fetchAllUsers();
                }, 2000);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Check
              type={"checkbox"}
              id={`showInactive`}
              label={`Show inactive`}
              checked={showInactive}
              onClick={(e) => {
                console.log(e.target.checked);
                setShowInactive(e.target.checked);
                ShowInActiveRef.current = e.target.checked;
                setTimeout(() => {
                  fetchAllUsers();
                }, 2000);
              }}
            />
          </div>
        </div>
        <div>No users to display...</div>{" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.auth.tokens);
  return { tokens: state.auth.tokens };
};
export default connect(mapStateToProps, { logout })(Customers);
