import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { ChoosePhotoWidget, ProfileCardWidget } from "../../../components/Widgets";
import { Col, Row, Card, Form, Button, InputGroup ,Spinner} from '@themesberg/react-bootstrap';

import { Multiselect } from 'multiselect-react-dropdown';

import { Link } from 'react-router-dom';
import axios from "../../../api";
import Profile3 from "../../../assets/img/team/profile-picture-3.jpg";
import history from "../../../history";
import { connect } from "react-redux";
import {logout} from "../../../actions"

class CreateModels extends Component {
  state={   
    name:null,   
    picture:null,
    category:[],
  }

  
createModel=async()=>{  
    if(this.state.picture && this.state.name && this.state.category.length >0){
      if((this.state.picture.type === "image/jpeg" || this.state.picture.type === "image/gif" || this.state.picture.type === "image/png") && this.state.picture.size <= 800000){

        this.setState({modelCreateLoader:true})
        var formData=new FormData();
        console.log(this.state.packages);
        formData.append("name",this.state.name);
        formData.append("picture",this.state.picture);
        this.state.category.forEach(category=>{
          formData.append("category",category.id);
        })
        try{
          const res=await axios.post("/v1/models",formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${this.props?.tokens?.access?.token}`
            }
          })

          
          this.setState({modelCreateLoader:false})
          console.log(res.data);
          history.push("/components/listmodels")
        }
        catch(err){
        this.setState({modelCreateLoader:false})
        console.log(err)
        if(err.response&&(err.response.status==401||err.response.status==403)){
          this.props.logout(String(window.location.href));
      }
    
        }
      }else{
        alert("file size should be jpg,png or gif and must be less than 800kb")
      }
    }
    else{
      if(!this.state.picture){
        alert("please add image")
      }
      else if(!this.state.name){
        alert("please add name")
      }
      else if(this.state.category.length <=0){
        alert("please select category")
      }
    }    
    
  
}

showSpinner = ()=>{
  if(this.state.modelCreateLoader){
    return(
      <Spinner animation="border" role="status" style={{margin:"0 3rem"}}>
      <span className="sr-only">Loading...</span>
    </Spinner>
    )
  }else{
    return "Loading..."
  }
}

onSave=async(e)=>{
  e.preventDefault()

  console.log(this.state);
await this.createModel();
}
OnPhotoSelect = (event)=>{
  console.log(event.target.files[0])
  this.setState({picture:event.target.files[0]})
  // if(event.target.files[0]){
  //   var formData = new FormData();
  // formData.append("profile",event.target.files[0])
  // }
}

onSelect=(selectedItem)=>{
  console.log(selectedItem);
   this.setState({category:selectedItem})
 }
 
 onRemove=(selectedItem)=>{
   this.setState({category:selectedItem})
   console.log(this.state.category);
 }
  render(){


  console.log(this.props?.tokens?.access?.token);
    // console.log(this.state.package);
  return (<>
     {this.state.modelCreateLoader&&<span className="loading">Loading...</span>}                                                
      <Row>
        <Col xs={12} >
        <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Create Model</h5>
        <Form  onSubmit={this.onSave}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="modelName">
                <Form.Label>Model Name</Form.Label>
                <Form.Control onInput={e=>this.setState({name:e.target.value})} value={this.state.name} required type="text" placeholder="Enter the model name" />
              </Form.Group>
            </Col>
            <Col>
            <Form.Group id="category">
                <Form.Label>Category</Form.Label>
                <Multiselect
               
                  options={this.props.category}
                  placeholder="Select Category"
                  selectedValues={this.state.category}
                  onSelect={this.onSelect} // Function will trigger on select event
                  onRemove={this.onRemove} // Function will trigger on remove event
                  displayValue="categoryName" // Property name to display in the dropdown options
                  />
                  </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select model picture"
                photo={this.state.picture?URL.createObjectURL(this.state.picture):null}
                OnPhotoSelect={this.OnPhotoSelect}
              />
            </Col>
          </Row>
          <div className="mt-3">
          <Button variant="primary" type="submit" >Create Model</Button>
            {/* <Button variant="primary" type="submit" >{this.state.modelCreateLoader?this.showSpinner():"Create Model"}</Button> */}
          </div>

        </Form>
      </Card.Body>
    </Card>
        </Col>

      </Row>
    </>
  );
  }
};
const mapStateToProps=(state)=>{
  return ({tokens:state.auth.tokens,category:state.current.category})
}
export default connect(mapStateToProps,{logout})(CreateModels)