import React from "react"
import CardUi from "../Cards/CardUI"
import defaultImg from "../../../assets/img/team/profile-picture-1.jpg"
import axios from "../../../api";
import Loader from "../helpers/Loader"
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import history from "../../../history";
import {connect} from "react-redux";
import {logout} from "../../../actions"
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
class ListModels extends React.Component {
    state = { models: [], isLoading: true,searchedName: "" }

    componentDidMount() {
        this.fetchModels()
    }

    searchName = async ($event) =>{
        console.log($event.target.value)
        this.setState({searchedName:$event.target.value})
        try{  
        this.setState({isLoading:true})
          let res = []
          if($event.target.value){
            res=(await axios.get(`/v1/models?name=`+$event.target.value, {
                    headers: {
                      Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                    }
                  })).data.data
          }
          else{
            res=(await axios.get(`/v1/models`,{
                    headers: {
                      Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                    }
                  })).data.data        
          }
          console.log("result",res)
          const models = res.result
           this.setState({ models })
        //   setHairStyleCount(res.count)
        //   setPageCount(Math.ceil(res.count / itemsPerPage));                                
          this.setState({isLoading:false})
        }catch(err){
            this.setState({isLoading:true})
          if(err.response&&(err.response.status==401||err.response.status==403)){
            this.props.logout(String(window.location.href));
        }
    
        console.log(err);
        }    
      }    

    fetchModels = async () => {
        try {
            const res = await axios.get(`/v1/models`, {
                headers: {
                  Authorization: `Bearer ${this.props?.tokens?.access?.token}`
                }
              })
            this.setState({ models: res.data.data.result })
            console.log(this.state.models);
            this.setState({ isLoading: false })
        } catch (err) {
            console.log(err);
            this.setState({ isLoading: true })
            if(err.response&&(err.response.status==401||err.response.status==403)){
                this.props.logout(String(window.location.href));
            }
        }
    }
    deleteModel = async (id) => {
        try{
        await axios.delete(`/v1/models/${id}`, {
            headers: {
                Authorization:`Bearer ${this.props?.tokens?.access?.token}`
            }
        })
        this.setState({ models: this.state.models.filter((model) => model._id != id) })}catch(err){
            if(err.response&&(err.response.status==401||err.response.status==403)){
                this.props.logout(String(window.location.href));
            }
        }
    }

    displayImage = (img) => `data:image/png;base64,${img}`

    renderModels = () => this.state.models.map(model => {
        return (
            <div style={{margin:"5px"}}>
                <CardUi imgSrc={model.picture ? this.displayImage(model.picture) : defaultImg}
                    editButton="Edit Model"
                    deleteButton="Delete Model"
                    isModel={true}
                    title={`${model.name}`}
                    deleteItem={() => this.deleteModel(model._id)}
                    editItem={()=>history.push(`/components/editmodels/${model._id}`)}
                ></CardUi>
                </div>
        )
    })
    onButtonClick = () => {
        console.log("button is clicked")
    }


    createModel = () => {
        history.push("/components/createmodels")

    }
    render() {
            return (
                <div>
                    {this.state.isLoading&&<span className="loading">Loading...</span>}                                                                    
                <div className="container-fluid justify-content-center">                    
                    <div style={{margin: "10px"}} className="d-flex align-items-center">
                            <Form className="navbar-search">
                            <Form.Group id="topbarSearch">
                                <InputGroup className="input-group-merge search-bar">
                                <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                                <Form.Control onChange={this.searchName}  type="text" placeholder="Search" />
                                </InputGroup>
                            </Form.Group>
                            </Form>
                        </div>                
                    <Button variant="contained"
                        style={{ backgroundColor: "#262B40", color: "white" }}
                        disableElevation startIcon={<Add />}
                        size="large"
                        onClick={this.createModel}
                    >
                        Create Model
                </Button>
                <div style={{padding:"20px 0",display:'flex',justifyContent:'center',alignItems: 'center',flexWrap:"wrap"}}>

                        {this.state.models.length > 0 ? this.renderModels() : <h3>No models found Please add some!!</h3>}

                    </div>
                </div>
            </div>                
            )        
    }
}

const mapStateToProps = (state) => {
    return ({ tokens: state.auth.tokens })
}

export default connect(mapStateToProps,{logout})(ListModels);