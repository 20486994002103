import React,{useState} from "react"
import Grid from "../helpers/Grid"
import {Modal,Button} from "@themesberg/react-bootstrap"
import axios from "../../../api"
import Loader from "../helpers/Loader";
import {connect} from "react-redux";
import {logout} from "../../../actions"
const ReactModal = (props)=>{

  const[selectedHairstyles,setSelectedHairstyles]=useState([])
  const selectHairstyle=(hairstyles)=>{
    setSelectedHairstyles(hairstyles)
    console.log(hairstyles);
  }
  const addHairstyleToPackage=async()=>{
    try{
    await Promise.all(
      selectedHairstyles.map(async(hairstyle)=>{
        axios.patch(`v1/packages/${props.package}/addHairstyle/${hairstyle.id}`, {
          headers: {
            Authorization: `Bearer ${props?.tokens?.access?.token}`
          }
        })
      })
    )

    props.removeFilteredHairstyles(selectedHairstyles)
    props.addHairstyles(selectedHairstyles)
    props.hideModal()
    }catch(err){
      console.log(err);
      if(err.response&&(err.response.status==401||err.response.status==403)){
        props.logout(String(window.location.href));
    }
    }
  }
    return( 
        <Modal show={props.show} onHide={props.hideModal} size="xl"   backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.items.length > 0 ?
            <Grid selectHairstyle={selectHairstyle} items={props.items}></Grid>:
            <Loader coverHeight={300} coverWidth={250}></Loader>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.hideModal}>
            Close
          </Button>
          <Button  variant="primary" onClick={addHairstyleToPackage}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    )

}
const mapStateToProps = (state) => {
  return ({ tokens: state.auth.tokens })
}

export default connect(mapStateToProps,{logout})(ReactModal);