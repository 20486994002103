import React from "react"
import "./card-style.css"
const CardUI = props =>{
    const renderBtn=()=>{
        if(!props.btnText){
            return null;
        }
        return(
            <>
            <div>
            <button className="btn btn-outline-success"  onClick={props.onButtonClick}>{props.btnText}</button>

            </div>
            
            </>
        )
    }

    return(
        <div className="card text-center shadow cardUI"> 
        <div className="overflow">
            <img src={props.imgSrc} className="card-img-top"></img>
        </div>
        <div className="card-body text-dark cardUI-body">
            <h4 className="card-title">{props.isModel || props.ListHairstyles?`${props.title}`:props.title === 0?"Free":`$${props.title}`}</h4>
            {props.isModel || props.ListHairstyles?null:<p className="text-secondary cardUI-text">{`Number of Hairstyles: ${props.numberOfHairstyles}`}</p>}
            <p className="card-text text-secondary cardUI-text card-description-hide">{props.description}</p>
            {renderBtn()}
            <div className="mt-2">
            {props.editButton?<button onClick={props.editItem} className="btn btn-outline-success" style={{marginRight:"3px"}}  >{props.editButton}</button>:null}
            {props.editHairstyle?<button onClick={props.editHairstyle} className="btn btn-outline-success" style={{marginRight:"3px"}}  >{`Edit`}</button>:null}
            <button className="btn btn-outline-success"  onClick={props.deleteItem} >{props.deleteButton}</button>
            </div>
            </div>
        </div>
    )
}


export default CardUI